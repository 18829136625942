import '@ibm/plex/css/ibm-plex.css';
import React from 'react';
import { Outlet } from 'react-router-dom';
import { HistoryBackProvider } from './util/history/use-history-back';

export function App() {
  return (
    <HistoryBackProvider>
      <Outlet />
    </HistoryBackProvider>
  );
}

export default App;
