import React from 'react';
import { BreadcrumbRouteObject } from '../../ui/breadcrumbs/breadcrumbs';
import { HelmetRouteObject } from '../../ui/helmet/helmet-outlet';
import Translate from '../../util/translate/translate';

const helpSystemRoutes: (BreadcrumbRouteObject & HelmetRouteObject)[] = [
  {
    path: 'help-system',
    handle: {
      breadcrumb: <Translate ns="help_system" i18nKey="helpSystem" />,
    },
    children: [
      {
        index: true,
        lazy: () => import('./help-text-lister/help-text-lister-route'),
      },
    ],
  },
];

export default helpSystemRoutes;
