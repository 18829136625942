/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { CompanyReferenceDto } from './CompanyReferenceDto';
import {
    CompanyReferenceDtoFromJSON,
    CompanyReferenceDtoFromJSONTyped,
    CompanyReferenceDtoToJSON,
} from './CompanyReferenceDto';
import type { EditionReferenceDto } from './EditionReferenceDto';
import {
    EditionReferenceDtoFromJSON,
    EditionReferenceDtoFromJSONTyped,
    EditionReferenceDtoToJSON,
} from './EditionReferenceDto';
import type { SectionReferenceDto } from './SectionReferenceDto';
import {
    SectionReferenceDtoFromJSON,
    SectionReferenceDtoFromJSONTyped,
    SectionReferenceDtoToJSON,
} from './SectionReferenceDto';
import type { TaxiVoucherStatusDto } from './TaxiVoucherStatusDto';
import {
    TaxiVoucherStatusDtoFromJSON,
    TaxiVoucherStatusDtoFromJSONTyped,
    TaxiVoucherStatusDtoToJSON,
} from './TaxiVoucherStatusDto';
import type { VersionDto } from './VersionDto';
import {
    VersionDtoFromJSON,
    VersionDtoFromJSONTyped,
    VersionDtoToJSON,
} from './VersionDto';

/**
 * A taxi voucher
 * @export
 * @interface TaxiVoucherDto
 */
export interface TaxiVoucherDto {
    /**
     * The unique identifier for an entity.
     * @type {string}
     * @memberof TaxiVoucherDto
     */
    id: string;
    /**
     * 
     * @type {VersionDto}
     * @memberof TaxiVoucherDto
     */
    version?: VersionDto;
    /**
     * 
     * @type {string}
     * @memberof TaxiVoucherDto
     */
    receiptNumber: string;
    /**
     * 
     * @type {EditionReferenceDto}
     * @memberof TaxiVoucherDto
     */
    edition: EditionReferenceDto;
    /**
     * 
     * @type {SectionReferenceDto}
     * @memberof TaxiVoucherDto
     */
    section: SectionReferenceDto;
    /**
     * 
     * @type {CompanyReferenceDto}
     * @memberof TaxiVoucherDto
     */
    taxiCompany?: CompanyReferenceDto;
    /**
     * 
     * @type {string}
     * @memberof TaxiVoucherDto
     */
    contactPerson?: string;
    /**
     * 
     * @type {TaxiVoucherStatusDto}
     * @memberof TaxiVoucherDto
     */
    status: TaxiVoucherStatusDto;
}

/**
 * Check if a given object implements the TaxiVoucherDto interface.
 */
export function instanceOfTaxiVoucherDto(value: object): boolean {
    if (!('id' in value)) return false;
    if (!('receiptNumber' in value)) return false;
    if (!('edition' in value)) return false;
    if (!('section' in value)) return false;
    if (!('status' in value)) return false;
    return true;
}

export function TaxiVoucherDtoFromJSON(json: any): TaxiVoucherDto {
    return TaxiVoucherDtoFromJSONTyped(json, false);
}

export function TaxiVoucherDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): TaxiVoucherDto {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'version': json['version'] == null ? undefined : VersionDtoFromJSON(json['version']),
        'receiptNumber': json['receiptNumber'],
        'edition': EditionReferenceDtoFromJSON(json['edition']),
        'section': SectionReferenceDtoFromJSON(json['section']),
        'taxiCompany': json['taxiCompany'] == null ? undefined : CompanyReferenceDtoFromJSON(json['taxiCompany']),
        'contactPerson': json['contactPerson'] == null ? undefined : json['contactPerson'],
        'status': TaxiVoucherStatusDtoFromJSON(json['status']),
    };
}

export function TaxiVoucherDtoToJSON(value?: TaxiVoucherDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'version': VersionDtoToJSON(value['version']),
        'receiptNumber': value['receiptNumber'],
        'edition': EditionReferenceDtoToJSON(value['edition']),
        'section': SectionReferenceDtoToJSON(value['section']),
        'taxiCompany': CompanyReferenceDtoToJSON(value['taxiCompany']),
        'contactPerson': value['contactPerson'],
        'status': TaxiVoucherStatusDtoToJSON(value['status']),
    };
}

