import {
  Button,
  ButtonGroup,
  HStack,
  IconButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spacer,
  Stack,
  StackDivider,
} from '@chakra-ui/react';
import { faTrashAlt } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { remove } from 'lodash-es';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { AccessGroupReferenceDto, EditionDto, PersonDto } from '../../../../api';
import accessGroupApi from '../../../../data-access/access-group-api';
import personAccessGroupApi from '../../../../data-access/person-access-group-api';
import Form from '../../../../ui/form/form';
import FormControl from '../../../../ui/form/form-control';
import MultiValueAsyncSelectFormControl from '../../../../ui/form/select-control/multi-value-async-select-form-control';
import SubmitButton from '../../../../ui/form/submit-button';
import ValueSelect from '../../../../ui/select/value-select';
import useToast from '../../../../ui/use-toast/use-toast';
import AccessGroupReference from '../../../access-group/access-group-reference/access-group-reference';
import useActiveEdition from '../../../edition/use-active-edition/use-active-edition';
import { HelpSystemBranchProvider } from '../../../help-system/common/help-system-context';
import { personAccessGroupFetcher } from '../person-access-group-queries';

interface AccessGroupEditorDialogProps {
  isOpen: boolean;
  onClose: () => void;
  person: PersonDto;
}

export default function AccessGroupEditorDialog({ isOpen, onClose, person }: AccessGroupEditorDialogProps) {
  const initialFocusRef = React.useRef<HTMLInputElement>(null);

  return (
    <Modal isOpen={isOpen} onClose={onClose} closeOnOverlayClick={false} initialFocusRef={initialFocusRef}>
      <ModalOverlay />
      <ModalContent>
        <AccessGroupEditor onClose={onClose} person={person} initialFocusRef={initialFocusRef} />
      </ModalContent>
    </Modal>
  );
}

interface AccessGroupEditorProps {
  onClose: () => void;
  person: PersonDto;
  initialFocusRef: React.RefObject<HTMLInputElement>;
}

type AddAccessGroupsFormData = {
  personId: string;
  accessGroups: AccessGroupReferenceDto[];
  editionId: string;
};

function AccessGroupEditor({ onClose, person, initialFocusRef }: AccessGroupEditorProps) {
  const { t } = useTranslation(['access_group', 'edition']);
  const { t: tCommon } = useTranslation('common');

  const activeEdition = useActiveEdition();
  const form = useForm<AddAccessGroupsFormData>({
    mode: 'all',
    defaultValues: {
      accessGroups: [],
      personId: person.id!,
      editionId: activeEdition.id!,
    },
  });

  const accessGroups = form.watch('accessGroups');
  const setValue = form.setValue;

  const showSuccessToast = useToast({
    status: 'success',
  });

  const handleAdd = async (data: AddAccessGroupsFormData) => {
    await personAccessGroupApi.createRelationsByPerson({
      addAccessGroupsDto: {
        ...data,
        accessGroupIds: data.accessGroups.map((value) => value.id),
      },
    });

    personAccessGroupFetcher.mutate();

    showSuccessToast({
      title: (
        <Trans t={t} i18nKey="access_group:connections.toast.successTitle" values={{ count: accessGroups.length }} />
      ),
      description: (
        <Trans
          t={t}
          i18nKey="access_group:connections.toast.successDescription"
          values={{ count: accessGroups.length }}
        />
      ),
    });

    onClose();
  };

  const validateNoRemoteDuplicate = async (accessGroups: AccessGroupReferenceDto[]) => {
    for (const accessGroup of accessGroups) {
      const isUnique = await personAccessGroupApi.isUnique({
        personId: person.id,
        accessGroupId: accessGroup.id,
        editionId: activeEdition.id,
      });

      if (!isUnique.value) {
        return t('access_group:connections.notUnique', { accessGroup: accessGroup.title });
      }
    }

    return true;
  };

  return (
    <HelpSystemBranchProvider pathPrefix="personAccessGroup.editor">
      <FormProvider {...form}>
        <Form onValid={handleAdd} initialFocusRef={initialFocusRef}>
          <ModalHeader>{t('access_group:connections.add')}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Stack spacing={4}>
              <MultiValueAsyncSelectFormControl<AccessGroupReferenceDto>
                name="accessGroups"
                label={t('access_group:accessGroup')}
                controlShouldRenderValue={false}
                backspaceRemovesValue={false}
                isClearable={false}
                isRequired
                ref={initialFocusRef}
                placeholder={tCommon('select.placeholder')}
                loadOptions={async (value: string) => {
                  const groups = await accessGroupApi.searchAccessGroupListItems({
                    q: value,
                    filter: ['status,eq,ACTIVE'],
                  });
                  return groups.content.map((group) => ({
                    id: group.id,
                    title: group.title,
                    englishTitle: group.englishTitle,
                  }));
                }}
                renderLabel={(option) => option.title!}
                optionIdentifier={(option) => option.id}
                rules={{ validate: { validateNoRemoteDuplicate } }}
              />
              {accessGroups.length > 0 && (
                <Stack
                  borderWidth="thin"
                  borderColor="border.01"
                  borderRadius="base"
                  divider={<StackDivider borderColor="border.01" />}
                  spacing={0}
                  padding={0}
                >
                  {accessGroups.map((value, idx) => {
                    return (
                      <HStack key={idx} p={1} pl={4}>
                        <AccessGroupReference accessGroup={value} />
                        <Spacer />
                        <IconButton
                          size="sm"
                          variant="ghost"
                          colorScheme="red"
                          aria-label={tCommon('action.delete')}
                          onClick={() => {
                            const newAccessGroups = [...accessGroups];
                            remove(newAccessGroups, (v) => v.id === value.id);
                            setValue('accessGroups', [...newAccessGroups], { shouldValidate: true });
                          }}
                          icon={<FontAwesomeIcon icon={faTrashAlt} />}
                        />
                      </HStack>
                    );
                  })}
                </Stack>
              )}
              <FormControl name="editionId" label={t('edition:edition')} isRequired>
                <ValueSelect<EditionDto>
                  isRequired
                  options={[activeEdition]}
                  renderLabel={(edition) => edition.name}
                  onChange={() => {}}
                  value={activeEdition}
                  defaultValue={activeEdition}
                  isDisabled
                />
              </FormControl>
            </Stack>
          </ModalBody>
          <ModalFooter>
            <ButtonGroup spacing={4}>
              <Button onClick={onClose}>{tCommon('action.abort')}</Button>
              <SubmitButton variant="primary" isDisabled={accessGroups.length === 0}>
                {tCommon('action.add')}
              </SubmitButton>
            </ButtonGroup>
          </ModalFooter>
        </Form>
      </FormProvider>
    </HelpSystemBranchProvider>
  );
}
