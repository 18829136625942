import { Alert, AlertIcon, Stack, Text } from '@chakra-ui/react';
import { faPlus } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import invariant from 'tiny-invariant';
import {
  CompanyDto,
  OccupationDto,
  OccupationDtoStatusEnum,
  PersonReferenceDto,
  SyncStatusInnerDto,
} from '../../../api';
import accountApi from '../../../data-access/account-api';
import companyAdminApi from '../../../data-access/company-admin-api';
import personApi from '../../../data-access/person-api';
import Form from '../../../ui/form/form';
import FormControl from '../../../ui/form/form-control';
import ValueSelectControl from '../../../ui/form/select-control/value-select-control';
import SubmitButton from '../../../ui/form/submit-button';
import PersonSelectControl from '../../common/form/person-select-control/person-select-control';
import { AddCompanyAdminDto } from './company-admin-editor-dialog-content';

interface CompanyAdminEditorFormProps {
  initialFocusRef: React.RefObject<HTMLInputElement>;
  addedPersons: AddCompanyAdminDto[];
  company: CompanyDto;
  handleCompanyAdminAdded: (dto: AddCompanyAdminDto) => void;
}

export default function CompanyAdminEditorForm({
  initialFocusRef,
  addedPersons,
  company,
  handleCompanyAdminAdded,
}: CompanyAdminEditorFormProps) {
  const { t } = useTranslation(['common', 'person', 'company', 'account']);
  const form = useForm<AddCompanyAdminDto>({
    mode: 'all',
  });

  const formIsDirty = Object.keys(form.formState.dirtyFields).length > 0;
  const currentPerson = form.watch('person');

  const [occupations, setOccupations] = useState<OccupationDto[] | null>(null);
  const [syncStatus, setSyncStatus] = React.useState<SyncStatusInnerDto[] | null>(null);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);

  const validatePerson = async (person: PersonReferenceDto) => {
    const { numberOfExistingRelations, occupationExists } = await companyAdminApi.canCreateExternalCompanyAdminRelation(
      {
        personId: person.id,
        companyId: company.id,
      },
    );

    const numberOfAllAdmins = numberOfExistingRelations + addedPersons.length;

    if (numberOfAllAdmins >= 3) {
      return t('company:externalAdmin.limit');
    }
    if (!occupationExists) {
      return t('company:externalAdmin.occupationMissing');
    }

    return true;
  };

  const validateOccupation = async (occupation: OccupationDto) => {
    invariant(currentPerson?.id != null, 'person must be set');

    const isUnique = await companyAdminApi.isCompanyAdminUnique({
      personId: currentPerson.id,
      companyId: company.id,
      occupationId: occupation.id,
    });

    const isAlreadyAdded = addedPersons.find((p) => p.occupation.id === occupation.id) != null;
    if (isAlreadyAdded) {
      return t('company:externalAdmin.notLocallyUnique');
    }

    return isUnique.value || t('company:externalAdmin.notUnique');
  };

  const validateAccountEmail = async (email: string) => {
    const status = getSyncStatus(email);
    if (status?.status === 'MAILING_BLOCKLIST') {
      return t('account:editor.emailValidationBlocklist');
    } else if (status?.status === 'PROFESSIONAL') {
      return t('account:editor.emailValidationProfessionalAccount');
    }
    return true;
  };

  const getSyncStatus = (email: string) => {
    return syncStatus == null ? null : syncStatus.find((info) => info.email === email);
  };

  const renderEmailLabel = (email: string) => {
    const syncStatus = getSyncStatus(email);
    const color = syncStatus?.status === 'NO_ACCOUNT' || syncStatus?.status === 'BASIC' ? undefined : 'text.muted';
    return <Text color={color}>{getEmailLabel(email)}</Text>;
  };

  const getEmailLabel = (email: string) => {
    const syncStatus = getSyncStatus(email);
    if (syncStatus == null) {
      return email;
    }
    return syncStatus.status === 'NO_ACCOUNT' ? email : `${email} (${t(`account:syncOptions.${syncStatus.status}`)})`;
  };

  const reset = () => {
    form.reset();
    setOccupations(null);
    setSyncStatus(null);
  };

  return (
    <Stack>
      <Alert status="info">
        <AlertIcon />
        {t('company:externalAdmin.accountAlert')}
      </Alert>
      <FormProvider {...form}>
        <Form<AddCompanyAdminDto>
          onValid={(data) => {
            handleCompanyAdminAdded(data);
            reset();
          }}
          initialFocusRef={initialFocusRef}
        >
          <Stack padding={4} bgColor="background.highlight" borderRadius="base" spacing={4} mb={4}>
            <FormControl<AddCompanyAdminDto> name="person" label={t('person:person')} isRequired>
              <PersonSelectControl
                name="person"
                isRequired
                ref={initialFocusRef}
                label={t('person:person')}
                onChange={(newPerson) => {
                  if (newPerson == null) {
                    form.resetField('occupation');
                    form.resetField('accountEmailAddress');
                  } else if (currentPerson !== newPerson) {
                    form.resetField('occupation');
                    form.resetField('accountEmailAddress');
                    setIsLoading(true);
                    personApi.fetchPerson({ id: newPerson.id }).then((person) => {
                      setOccupations(person.occupations ?? []);
                      personApi.hasProfessionalAccount({ id: newPerson.id }).then((hasAccount) => {
                        if (!hasAccount) {
                          accountApi
                            .getSyncStatus({
                              emails:
                                person.emailAddresses == null ? [] : person.emailAddresses?.map((value) => value.email),
                            })
                            .then((syncInfo) => {
                              setSyncStatus(syncInfo);
                              setIsLoading(false);
                            });
                        } else {
                          setSyncStatus(null);
                          setIsLoading(false);
                        }
                      });
                    });
                  }
                }}
                rules={{ validate: validatePerson }}
              />
            </FormControl>
            <FormControl label={t('person:occupations.label')} name="occupation" isRequired>
              <ValueSelectControl<OccupationDto>
                label={t('person:occupations.label')}
                name="occupation"
                isRequired
                options={
                  occupations == null
                    ? []
                    : occupations.filter(
                        (occ) =>
                          occ.connectedCompany?.id === company.id && occ.status != OccupationDtoStatusEnum.TERMINATED,
                      )
                }
                renderLabel={(occupation) => occupation.jobTitle}
                rules={{
                  validate: {
                    validateOccupation,
                  },
                }}
              />
            </FormControl>
            {syncStatus != null && (
              <FormControl label={t('account:accountEmail')} name="accountEmailAddress" isRequired>
                <ValueSelectControl<string>
                  label={t('account:accountEmail')}
                  name="accountEmailAddress"
                  isRequired
                  options={syncStatus.map((value) => value.email)}
                  renderLabel={renderEmailLabel}
                  getStringValue={getEmailLabel}
                  rules={{ validate: validateAccountEmail }}
                />
              </FormControl>
            )}
            <SubmitButton
              leftIcon={<FontAwesomeIcon icon={faPlus} />}
              size="sm"
              variant="outline"
              isDisabled={!formIsDirty || isLoading}
              alignSelf="stretch"
            >
              {t('common:action.add')}
            </SubmitButton>
          </Stack>
        </Form>
      </FormProvider>
    </Stack>
  );
}
