import React from 'react';
import { FieldPath, FieldValues } from 'react-hook-form';
import FormControl from '../form-control';
import MultiValueAsyncSelectControl, { MultiValueAsyncSelectControlProps } from './multi-value-async-select-control';

export interface MultiValueAsyncSelectFormControlProps<
  TOption,
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> extends MultiValueAsyncSelectControlProps<TOption, TFieldValues, TName> {
  helperText?: React.ReactNode;
}

function MultiValueAsyncSelectFormControl<
  TOption,
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>(
  {
    name,
    label,
    helperText,
    isRequired,
    size,
    isDisabled,
    ...props
  }: MultiValueAsyncSelectFormControlProps<TOption, TFieldValues, TName>,
  ref: React.ForwardedRef<HTMLInputElement>,
) {
  return (
    <FormControl
      label={label}
      name={name}
      isRequired={isRequired}
      helperText={helperText}
      size={size}
      isDisabled={isDisabled}
    >
      <MultiValueAsyncSelectControl
        label={label}
        name={name}
        isRequired={isRequired}
        ref={ref}
        size={size}
        isDisabled={isDisabled}
        {...props}
      />
    </FormControl>
  );
}

export default React.forwardRef(MultiValueAsyncSelectFormControl);
