/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  CreateTaxiVoucherDto,
  FailureDto,
  PageableDto,
  TaxiVoucherDto,
  TaxiVoucherPageDto,
  TaxiVoucherReferencePageDto,
} from '../models/index';
import {
    CreateTaxiVoucherDtoFromJSON,
    CreateTaxiVoucherDtoToJSON,
    FailureDtoFromJSON,
    FailureDtoToJSON,
    PageableDtoFromJSON,
    PageableDtoToJSON,
    TaxiVoucherDtoFromJSON,
    TaxiVoucherDtoToJSON,
    TaxiVoucherPageDtoFromJSON,
    TaxiVoucherPageDtoToJSON,
    TaxiVoucherReferencePageDtoFromJSON,
    TaxiVoucherReferencePageDtoToJSON,
} from '../models/index';

export interface CreateTaxiVoucherRequest {
    createTaxiVoucherDto: CreateTaxiVoucherDto;
}

export interface FetchTaxiVoucherRequest {
    id: string;
}

export interface SearchTaxiVoucherListItemsRequest {
    filter?: Array<string>;
    pageable?: PageableDto;
}

export interface SearchTaxiVouchereReferencesRequest {
    pageable?: PageableDto;
    searchquery?: string;
}

/**
 * 
 */
export class TaxiVoucherApi extends runtime.BaseAPI {

    /**
     * Create one or more new taxi vouchers.
     */
    async createTaxiVoucherRaw(requestParameters: CreateTaxiVoucherRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['createTaxiVoucherDto'] == null) {
            throw new runtime.RequiredError(
                'createTaxiVoucherDto',
                'Required parameter "createTaxiVoucherDto" was null or undefined when calling createTaxiVoucher().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/taxi-vouchers`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateTaxiVoucherDtoToJSON(requestParameters['createTaxiVoucherDto']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Create one or more new taxi vouchers.
     */
    async createTaxiVoucher(requestParameters: CreateTaxiVoucherRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.createTaxiVoucherRaw(requestParameters, initOverrides);
    }

    /**
     * Get the taxi voucher business object.
     */
    async fetchTaxiVoucherRaw(requestParameters: FetchTaxiVoucherRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<TaxiVoucherDto>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling fetchTaxiVoucher().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/taxi-vouchers/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TaxiVoucherDtoFromJSON(jsonValue));
    }

    /**
     * Get the taxi voucher business object.
     */
    async fetchTaxiVoucher(requestParameters: FetchTaxiVoucherRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<TaxiVoucherDto> {
        const response = await this.fetchTaxiVoucherRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Search for taxi vouchers.
     */
    async searchTaxiVoucherListItemsRaw(requestParameters: SearchTaxiVoucherListItemsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<TaxiVoucherPageDto>> {
        const queryParameters: any = {};

        if (requestParameters['filter'] != null) {
            queryParameters['filter'] = requestParameters['filter'];
        }

        if (requestParameters['pageable'] != null) {
            queryParameters['pageable'] = requestParameters['pageable'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/taxi-vouchers`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TaxiVoucherPageDtoFromJSON(jsonValue));
    }

    /**
     * Search for taxi vouchers.
     */
    async searchTaxiVoucherListItems(requestParameters: SearchTaxiVoucherListItemsRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<TaxiVoucherPageDto> {
        const response = await this.searchTaxiVoucherListItemsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Search for taxi vouchers.
     */
    async searchTaxiVouchereReferencesRaw(requestParameters: SearchTaxiVouchereReferencesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<TaxiVoucherReferencePageDto>> {
        const queryParameters: any = {};

        if (requestParameters['pageable'] != null) {
            queryParameters['pageable'] = requestParameters['pageable'];
        }

        if (requestParameters['searchquery'] != null) {
            queryParameters['searchquery'] = requestParameters['searchquery'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/taxi-vouchers/references`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TaxiVoucherReferencePageDtoFromJSON(jsonValue));
    }

    /**
     * Search for taxi vouchers.
     */
    async searchTaxiVouchereReferences(requestParameters: SearchTaxiVouchereReferencesRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<TaxiVoucherReferencePageDto> {
        const response = await this.searchTaxiVouchereReferencesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Fetch the template for creating a new taxi voucher.
     */
    async templateTaxiVoucherRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<TaxiVoucherDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/taxi-vouchers/template`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TaxiVoucherDtoFromJSON(jsonValue));
    }

    /**
     * Fetch the template for creating a new taxi voucher.
     */
    async templateTaxiVoucher(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<TaxiVoucherDto> {
        const response = await this.templateTaxiVoucherRaw(initOverrides);
        return await response.value();
    }

}
