import { Box, Flex } from '@chakra-ui/react';
import { ReactNode, useContext } from 'react';
import { HelpSystemBranchContext, HelpSystemRootContext, prefixPath } from './help-system-context';

export default function HelpTextInspector({ path, children }: { path: string; children?: ReactNode }) {
  const rootContext = useContext(HelpSystemRootContext);
  const branchContext = useContext(HelpSystemBranchContext);

  if (rootContext?.inspectEnabled && branchContext != null) {
    children = (
      <Flex position="relative" alignItems="baseline">
        {children}
        {rootContext.inspect && (
          <Box
            aria-hidden
            sx={{
              position: 'absolute',
              top: 0,
              left: '100%',
              bgColor: 'yellow.200',
              px: 1,
              border: '1px solid',
              borderColor: 'yellow.500',
              borderRadius: 'sm',
              opacity: 0.5,
              zIndex: 2,
            }}
          >
            {prefixPath(branchContext, path)}
          </Box>
        )}
      </Flex>
    );
  }

  return children;
}
