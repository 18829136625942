import { isEmpty } from 'lodash-es';
import { ReactNode, useContext, useMemo } from 'react';
import { HelpTextDto } from '../../../api';
import { HelpSystemBranchContext, HelpSystemRootContext, prefixPath } from './help-system-context';
import HelpTextInspector from './help-text-inspector';

export default function HelpText({
  path,
  children,
}: {
  path: string;
  children(props: { helpText: HelpTextDto }): ReactNode;
}) {
  const helpText = useHelpText(path);

  if (helpText == null) {
    return <HelpTextInspector path={path} />;
  }

  return <HelpTextInspector path={path}>{children({ helpText })}</HelpTextInspector>;
}

export function useHelpText(path: string) {
  const rootContext = useContext(HelpSystemRootContext);
  const branchContext = useContext(HelpSystemBranchContext);

  return useMemo(() => {
    if (branchContext == null) {
      return;
    }

    const prefixedPath = prefixPath(branchContext, path);
    const helpText = branchContext.helpTexts.get(prefixedPath);

    if (!isEmpty(helpText?.text)) {
      return helpText;
    }

    if (helpText == null) {
      rootContext?.onMissingPath?.(prefixedPath);
    }
  }, [branchContext, path, rootContext]);
}
