import {
  Box,
  Button,
  ButtonGroup,
  Flex,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { faLink } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { isEmpty } from 'lodash-es';
import React, { useRef, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { CompanyDto, CompanyDtoStatusEnum, CreateCompanyFilmRelationsDto, FilmReferenceDto } from '../../../api';
import companyApi from '../../../data-access/company-api';
import filmApi from '../../../data-access/film-api';
import { DisableableButton, DisableableButtonProps } from '../../../ui/disableable-button/disableable-button';
import Form from '../../../ui/form/form';
import FormControl from '../../../ui/form/form-control';
import MultiValueAsyncSelectControl from '../../../ui/form/select-control/multi-value-async-select-control';
import SubmitButton from '../../../ui/form/submit-button';
import { companyIcon, filmIcon } from '../../../ui/icons/business-objects';
import useToast from '../../../ui/use-toast/use-toast';
import { HelpSystemBranchProvider } from '../../help-system/common/help-system-context';
import ConnectionLine from '../../relations/common/connection-line';
import { relatedFilmFetcher } from '../company-queries';
import { CompanyFilmRoleSelectControl } from './company-film-role-select-control';

export interface AddFilmToCompanyButtonProps extends DisableableButtonProps {
  children: React.ReactNode;
  company: CompanyDto;
}

export const AddFilmToCompanyButton = React.forwardRef<HTMLButtonElement, AddFilmToCompanyButtonProps>(
  ({ children, company, ...props }, ref) => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const { t } = useTranslation(['common']);

    return (
      <>
        <DisableableButton
          ref={ref}
          {...props}
          onClick={onOpen}
          isDisabled={company.status !== CompanyDtoStatusEnum.ACTIVE}
          disableReason={t('common:toast.status_conflict.description')}
        >
          {children}
        </DisableableButton>
        <Modal isOpen={isOpen} onClose={onClose} closeOnOverlayClick={false} size="xl">
          <ModalOverlay />
          <ModalContent>
            <AddFilmToCompanyForm onClose={onClose} company={company} />
          </ModalContent>
        </Modal>
      </>
    );
  },
);

function AddFilmToCompanyForm({ onClose, company }: { onClose: () => void; company: CompanyDto }) {
  const form = useForm<CreateCompanyFilmRelationsDto>({
    mode: 'onSubmit',
    defaultValues: { films: [], role: undefined },
  });
  const { t } = useTranslation(['company', 'common']);
  const initialFocusRef = useRef<HTMLElement>(null);
  const [countryFilter, setCountryFilter] = useState<string>();
  const toast = useToast({ status: 'success' });

  const loadOptions = async (searchValue: string, pageSizeLimit: number): Promise<FilmReferenceDto[]> => {
    const page = await filmApi.searchConfirmedFilmReferences({
      q: searchValue,
      country: countryFilter,
      pageable: { size: pageSizeLimit, sort: ['title,ASC'] },
    });

    return page.content;
  };

  const validateNoDuplicate = React.useCallback(
    async (filmReferences: Array<FilmReferenceDto>) => {
      if (isEmpty(filmReferences)) {
        return true;
      }
      const existingRelations = await companyApi.companyFilmRelationsExist({
        companyId: company.id,
        filmIds: filmReferences.map((ref) => ref.id),
      });

      if (!isEmpty(existingRelations)) {
        return t('company:relation.film.exists', {
          existingRelations,
          count: existingRelations.length,
        });
      }
      return true;
    },
    [company.id, t],
  );

  const handleValid = async (createCompanyFilmRelationsDto: CreateCompanyFilmRelationsDto) => {
    await companyApi.createCompanyFilmRelations({ id: company.id, createCompanyFilmRelationsDto });
    toast({
      title: t('company:relation.film.toast.success.title'),
      description: t('company:relation.film.toast.success.description'),
    });
    await relatedFilmFetcher.mutate();
    onClose();
  };

  return (
    <HelpSystemBranchProvider pathPrefix="companyFilm.editor">
      <FormProvider {...form}>
        <Form<CreateCompanyFilmRelationsDto> onValid={handleValid} initialFocusRef={initialFocusRef}>
          <ModalHeader>{t('company:relation.film.action.add')}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Stack spacing={2} backgroundColor="background.highlight" pr={2} py={2} pl={3} borderRadius="base">
              <Flex position="relative" align="center">
                <Box as={FontAwesomeIcon} icon={companyIcon} fixedWidth flexShrink={0} />
                <Box ml={3}>
                  <Text as="span" fontWeight="medium">
                    {company.name}
                  </Text>
                </Box>
              </Flex>
              <Flex position="relative" align="center">
                <ConnectionLine position="top" spacing={2} />
                <Box as={FontAwesomeIcon} icon={faLink} fixedWidth flexShrink={0} />
                <ConnectionLine position="bottom" spacing={2} />
                <Stack
                  width="full"
                  spacing={4}
                  ml={3}
                  border="solid 1px"
                  borderColor="border.01"
                  borderWidth={1}
                  borderRadius={4}
                  px={4}
                  py={3}
                >
                  <CompanyFilmRoleSelectControl initialFocusRef={initialFocusRef} />
                </Stack>
              </Flex>
              <Flex align="center" position="relative">
                <ConnectionLine position="top" showArrow spacing={2} />
                <Box as={FontAwesomeIcon} icon={filmIcon} fixedWidth flexShrink={0} />
                <Box width="full" ml={3}>
                  <FormControl
                    label={t('company:relation.film.tabLabel')}
                    isRequired
                    name="films"
                    helperText={t('company:relation.film.filmHelperText')}
                  >
                    <Stack spacing={2}>
                      <Input
                        placeholder={t('company:relation.film.countryPlaceholder')}
                        aria-label={t('company:relation.film.countryPlaceholder')}
                        onChange={(event) => setCountryFilter(event.target.value)}
                        size="sm"
                        isInvalid={false}
                      />
                      <MultiValueAsyncSelectControl<FilmReferenceDto, CreateCompanyFilmRelationsDto, 'films'>
                        label={t('company:relation.film.tabLabel')}
                        aria-label={t('company:relation.film.tabLabel')}
                        optionIdentifier={({ id }) => id}
                        loadOptions={loadOptions}
                        renderLabel={({ title }) => title}
                        isRequired
                        name="films"
                        rules={{ validate: validateNoDuplicate }}
                      />
                    </Stack>
                  </FormControl>
                </Box>
              </Flex>
            </Stack>
          </ModalBody>
          <ModalFooter>
            <ButtonGroup spacing={4}>
              <Button onClick={onClose}>{t('common:action.abort')}</Button>
              <SubmitButton variant="primary" isDisabled={!form.formState.isDirty}>
                {t('common:action.save')}
              </SubmitButton>
            </ButtonGroup>
          </ModalFooter>
        </Form>
      </FormProvider>
    </HelpSystemBranchProvider>
  );
}
