/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { BlockElementDto } from './BlockElementDto';
import {
    BlockElementDtoFromJSON,
    BlockElementDtoFromJSONTyped,
    BlockElementDtoToJSON,
} from './BlockElementDto';
import type { VersionDto } from './VersionDto';
import {
    VersionDtoFromJSON,
    VersionDtoFromJSONTyped,
    VersionDtoToJSON,
} from './VersionDto';

/**
 * 
 * @export
 * @interface HelpTextDto
 */
export interface HelpTextDto {
    /**
     * The unique identifier for an entity.
     * @type {string}
     * @memberof HelpTextDto
     */
    id: string;
    /**
     * 
     * @type {VersionDto}
     * @memberof HelpTextDto
     */
    version?: VersionDto;
    /**
     * 
     * @type {string}
     * @memberof HelpTextDto
     */
    path: string;
    /**
     * 
     * @type {Array<BlockElementDto>}
     * @memberof HelpTextDto
     */
    text?: Array<BlockElementDto>;
}

/**
 * Check if a given object implements the HelpTextDto interface.
 */
export function instanceOfHelpTextDto(value: object): boolean {
    if (!('id' in value)) return false;
    if (!('path' in value)) return false;
    return true;
}

export function HelpTextDtoFromJSON(json: any): HelpTextDto {
    return HelpTextDtoFromJSONTyped(json, false);
}

export function HelpTextDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): HelpTextDto {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'version': json['version'] == null ? undefined : VersionDtoFromJSON(json['version']),
        'path': json['path'],
        'text': json['text'] == null ? undefined : ((json['text'] as Array<any>).map(BlockElementDtoFromJSON)),
    };
}

export function HelpTextDtoToJSON(value?: HelpTextDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'version': VersionDtoToJSON(value['version']),
        'path': value['path'],
        'text': value['text'] == null ? undefined : ((value['text'] as Array<any>).map(BlockElementDtoToJSON)),
    };
}

