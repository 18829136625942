import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { defineMultiStyleConfig, definePartsStyle } = createMultiStyleConfigHelpers([
  'editable',
  'container',
  'heading1',
  'heading2',
  'paragraph',
  'bold',
  'italic',
  'small',
  'link',
  'linkIcon',
  'linkButton',
  'citation',
  'citationQuote',
  'citationAuthor',
  'orderedList',
  'unorderedList',
]);

const baseStyle = definePartsStyle({
  editable: {
    paddingLeft: '16px',
    paddingRight: '16px',
    paddingTop: '8px',
    paddingBottom: '8px',
    border: '1px solid',
    borderColor: 'border.01',
    bgColor: 'layer.01',
    borderBottomLeftRadius: 'sm',
    borderBottomRightRadius: 'sm',
    minHeight: '50px',
    maxHeight: '300px',
    overflow: 'auto',
    resize: 'vertical',
    color: 'text',
    '--focus-color': 'colors.border.interactive',
    '--error-color': 'colors.border.error',
    _focus: {
      borderColor: 'var(--focus-color)',
      boxShadow: `0 0 0 1px var(--focus-color)`,
      outline: 'none',
    },
    '&[aria-invalid=true]': {
      borderColor: 'var(--error-color)',
      boxShadow: '0 0 0 1px var(--error-color)',
    },
    '&[data-toolbar]': {
      borderTopLeftRadius: 'sm',
      borderTopRightRadius: 'sm',
    },
  },
  linkIcon: { marginRight: '4px' },
});

const mailing = definePartsStyle({
  editable: {
    fontFamily: 'Arial, sans-serif',
  },
  heading1: {
    fontSize: '30px',
    lineHeight: '40px',

    '* + &': {
      marginTop: '1em',
    },
  },
  heading2: {
    fontSize: '26px',
    lineHeight: '30px',

    '* + &': {
      marginTop: '1em',
    },
  },
  paragraph: {
    fontSize: '16px',
    lineHeight: '20px',

    '* + &': {
      marginTop: '1em',
    },
  },
  link: {
    fontSize: 'inherit',
    lineHeight: 'inherit',
    fontFamily: 'inherit',
    color: '#D62E4A',
  },
  linkButton: {
    border: '1px solid #D62E4A',
    padding: '10px 25px',
    borderRadius: '40px',
    fontSize: '16px',
    lineHeight: '55px',
    color: '#D62E4A',
  },
  citation: {
    mx: 4,
    my: 6,

    '* + &': {
      marginTop: '1em',
    },
  },
});

const landingPage = definePartsStyle({
  editable: {
    fontFamily: 'landingPage',
    color: '#404040',

    '& > * + *': {
      marginTop: '15px',
    },
  },
  heading1: {
    fontSize: '2.875rem',
    lineHeight: '3.5rem',
  },
  heading2: {
    fontSize: '2.375rem',
    lineHeight: '3rem',
  },
  paragraph: {
    fontSize: '18px',
    lineHeight: '30px',
  },
  small: {
    fontSize: '16px',
    lineHeight: '20px',
  },
  link: {
    color: '#D62E4A',
    fontWeight: 'bold',
  },
  linkButton: {
    display: 'inline-flex',
    appearance: 'none',
    alignItems: 'center',
    justifyContent: 'center',
    lineHeight: 1.2,
    borderRadius: 'sm',
    fontWeight: 'bold',
    height: 10,
    minWidth: 10,
    fontSize: 'md',
    paddingX: 3.5,
    background: '#E5E5E5',
    color: '#404040',
  },
  citation: {
    mx: '20px',
  },
  citationQuote: {
    fontSize: '22px',
    lineHeight: '28px',
  },
  citationAuthor: {
    fontSize: '18px',
    lineHeight: '30px',
  },
  orderedList: {
    fontSize: '18px',
    lineHeight: '30px',
    listStylePosition: 'outside',
    marginLeft: '30px',
    '& > li': {
      paddingLeft: '5px',
    },
  },
  unorderedList: {
    fontSize: '18px',
    lineHeight: '30px',
    listStylePosition: 'outside',
    marginLeft: '30px',
    '& > li': {
      paddingLeft: '5px',
    },
  },
});

const berlinalx = definePartsStyle({
  container: {
    '& > * + *': {
      marginTop: 3,
    },
  },
  paragraph: {},
  small: {
    fontSize: 'sm',
  },
  link: {},
  strong: {
    fontWeight: 'semibold',
  },
  orderedList: {
    listStyleType: 'revert',
    marginInlineStart: '1em',
  },
  unorderedList: {
    listStyleType: 'revert',
    marginInlineStart: '1em',
  },
});

const RichText = defineMultiStyleConfig({
  baseStyle,
  variants: {
    mailing,
    landingPage,
    berlinalx,
  },
});

export default RichText;
