/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { SectionReferenceDto } from './SectionReferenceDto';
import {
    SectionReferenceDtoFromJSON,
    SectionReferenceDtoFromJSONTyped,
    SectionReferenceDtoToJSON,
} from './SectionReferenceDto';

/**
 * 
 * @export
 * @interface TaxiVoucherReferenceDto
 */
export interface TaxiVoucherReferenceDto {
    /**
     * The unique identifier for an entity.
     * @type {string}
     * @memberof TaxiVoucherReferenceDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof TaxiVoucherReferenceDto
     */
    receiptNumber: string;
    /**
     * 
     * @type {SectionReferenceDto}
     * @memberof TaxiVoucherReferenceDto
     */
    section: SectionReferenceDto;
}

/**
 * Check if a given object implements the TaxiVoucherReferenceDto interface.
 */
export function instanceOfTaxiVoucherReferenceDto(value: object): boolean {
    if (!('id' in value)) return false;
    if (!('receiptNumber' in value)) return false;
    if (!('section' in value)) return false;
    return true;
}

export function TaxiVoucherReferenceDtoFromJSON(json: any): TaxiVoucherReferenceDto {
    return TaxiVoucherReferenceDtoFromJSONTyped(json, false);
}

export function TaxiVoucherReferenceDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): TaxiVoucherReferenceDto {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'receiptNumber': json['receiptNumber'],
        'section': SectionReferenceDtoFromJSON(json['section']),
    };
}

export function TaxiVoucherReferenceDtoToJSON(value?: TaxiVoucherReferenceDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'receiptNumber': value['receiptNumber'],
        'section': SectionReferenceDtoToJSON(value['section']),
    };
}

