import React, { useEffect } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import invariant from 'tiny-invariant';
import { EditionDto, MailingConnectionDto } from '../../../api';
import mailingApi from '../../../data-access/mailing-api';
import ValueAsyncSelectFormControl from '../../../ui/form/select-control/value-async-select-form-control';
import ValueSelectFormControl from '../../../ui/form/select-control/value-select-form-control';
import useEditions from '../../edition/use-editions/use-editions';
import { CreateMailingModalFormData } from './create-mailing-modal';

export interface MailingConnectionSelectProps {
  label: string;
}

/**
 * Only to be used inside create-mailing-modal
 */
export default function MailingConnectionSelect({ label }: MailingConnectionSelectProps) {
  const { t } = useTranslation('edition');

  const { setValue } = useFormContext<CreateMailingModalFormData>();
  const template = useWatch<CreateMailingModalFormData, 'template'>({ name: 'template' });
  const edition = useWatch<CreateMailingModalFormData, 'edition'>({ name: 'edition' });

  const editions = useEditions();

  useEffect(() => {
    setValue('connection', undefined);
  }, [edition, setValue]);

  return (
    <>
      <ValueSelectFormControl<EditionDto, CreateMailingModalFormData>
        label={t('edition')}
        name="edition"
        isRequired
        options={editions.page.content}
        renderLabel={(edition) => edition.name}
      />
      <ValueAsyncSelectFormControl<MailingConnectionDto, CreateMailingModalFormData>
        name="connection"
        isRequired
        label={label}
        renderLabel={(connection) => connection.name}
        optionIdentifier={(connection) => connection.id}
        loadOptions={async (searchQuery: string, size: number) => {
          invariant(template != null);
          invariant(edition?.id != null);

          const page = await mailingApi.fetchAvailableConnections({
            templateId: template.id,
            editionId: edition.id,
            pageable: { size },
            q: searchQuery,
          });

          return page.content;
        }}
        defaultOptions={false}
      />
    </>
  );
}
