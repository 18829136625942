import React from 'react';
import { useParams } from 'react-router-dom';
import useFetcher from '../../../util/swr/use-fetcher';
import { fetchTaxiVoucher } from '../taxi-voucher-queries';
import { getTaxiVoucherId } from './get-taxi-voucher-id';

export default function TaxiVoucherBreadcrumb() {
  const taxiVoucher = useFetcher(fetchTaxiVoucher, { id: getTaxiVoucherId(useParams()) });

  return <>{taxiVoucher.receiptNumber}</>;
}
