import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
} from '@chakra-ui/react';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import invariant from 'tiny-invariant';
import { GroupDto, GroupPersonRelationDto, PersonReferenceDto, RelationTypeDto } from '../../../../../api';
import groupPersonApi from '../../../../../data-access/group-person-api';
import Form from '../../../../../ui/form/form';
import ValueSelectFormControl from '../../../../../ui/form/select-control/value-select-form-control';
import SubmitButton from '../../../../../ui/form/submit-button';
import useToast from '../../../../../ui/use-toast/use-toast';
import { HelpSystemBranchProvider } from '../../../../help-system/common/help-system-context';
import PersonReference from '../../../../person/person-reference/person-reference';
import { groupPersonFetcher } from '../group-person-queries';

interface PersonInGroupEditorDialogProps {
  group: GroupDto;
  groupPersonRelation: GroupPersonRelationDto;
  isOpen: boolean;
  onClose: () => void;
}

export default function PersonInGroupEditorDialog({
  group,
  groupPersonRelation,
  isOpen,
  onClose,
}: PersonInGroupEditorDialogProps) {
  const initialFocusRef = React.useRef<HTMLInputElement>(null);

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl" closeOnOverlayClick={false} initialFocusRef={initialFocusRef}>
      <ModalOverlay />
      <ModalContent>
        <PersonInGroupEditorModal
          group={group}
          groupPersonRelation={groupPersonRelation}
          initialFocusRef={initialFocusRef}
          onClose={onClose}
        />
      </ModalContent>
    </Modal>
  );
}

interface PersonInGroupEditorModalProps {
  group: GroupDto;
  groupPersonRelation: GroupPersonRelationDto;
  onClose: () => void;
  initialFocusRef?: React.RefObject<any>;
}

export function PersonInGroupEditorModal({
  group,
  groupPersonRelation,
  onClose,
  initialFocusRef,
}: PersonInGroupEditorModalProps) {
  const { t } = useTranslation(['common', 'group']);

  const form = useForm<GroupPersonRelationDto & { noPersonalData: boolean }>({
    mode: 'onBlur',
    defaultValues: {
      ...groupPersonRelation,
    },
  });

  const showSuccessToast = useToast({
    status: 'success',
  });

  const handleValid = async (relation: GroupPersonRelationDto) => {
    invariant(relation.id != null, 'Missing relation.id');
    invariant(group.id != null, 'Missing group.id');

    await groupPersonApi.updateGroupPersonRelation({
      id: relation.id,
      groupPersonRelationDto: relation,
    });

    showSuccessToast({
      title: t('group:connections.toast.edit_success.title'),
      description: t('group:connections.toast.edit_success.description'),
    });

    await groupPersonFetcher.mutate();
    onClose();
  };

  const formIsDirty = Object.keys(form.formState.dirtyFields).length > 0;
  const personReference = {
    id: groupPersonRelation.person.id,
    personKey: groupPersonRelation.person.personKey,
    firstName: groupPersonRelation.person.firstName,
    surname: groupPersonRelation.person.surname,
  } as PersonReferenceDto;

  return (
    <HelpSystemBranchProvider pathPrefix="groupPerson.edit">
      <FormProvider {...form}>
        <Form<GroupPersonRelationDto> onValid={handleValid} initialFocusRef={initialFocusRef}>
          <ModalHeader>{t(`group:connections.edit`)}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Stack spacing={4}>
              <Stack padding={4} bgColor="background.highlight" borderRadius="base" spacing={4} alignItems="start">
                <PersonReference personReference={personReference} />
              </Stack>

              <ValueSelectFormControl<RelationTypeDto>
                name="relationType"
                label={t('group:connections.relationType')}
                isRequired
                options={[RelationTypeDto.MAIN_GROUP, RelationTypeDto.SECONDARY_GROUP]}
                renderLabel={(type) => t(`group:connections.relationTypeOptions.${type}`)}
              />
            </Stack>
          </ModalBody>
          <ModalFooter>
            <Button mr={3} onClick={onClose}>
              {t('common:action.abort')}
            </Button>
            <SubmitButton variant="primary" isDisabled={!formIsDirty}>
              {t('common:action.save')}
            </SubmitButton>
          </ModalFooter>
        </Form>
      </FormProvider>
    </HelpSystemBranchProvider>
  );
}
