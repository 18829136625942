/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { SectionReferenceDto } from './SectionReferenceDto';
import {
    SectionReferenceDtoFromJSON,
    SectionReferenceDtoFromJSONTyped,
    SectionReferenceDtoToJSON,
} from './SectionReferenceDto';
import type { TaxiVoucherStatusDto } from './TaxiVoucherStatusDto';
import {
    TaxiVoucherStatusDtoFromJSON,
    TaxiVoucherStatusDtoFromJSONTyped,
    TaxiVoucherStatusDtoToJSON,
} from './TaxiVoucherStatusDto';
import type { VersionDto } from './VersionDto';
import {
    VersionDtoFromJSON,
    VersionDtoFromJSONTyped,
    VersionDtoToJSON,
} from './VersionDto';

/**
 * 
 * @export
 * @interface TaxiVoucherListItemDto
 */
export interface TaxiVoucherListItemDto {
    /**
     * The unique identifier for an entity.
     * @type {string}
     * @memberof TaxiVoucherListItemDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof TaxiVoucherListItemDto
     */
    receiptNumber: string;
    /**
     * 
     * @type {SectionReferenceDto}
     * @memberof TaxiVoucherListItemDto
     */
    section: SectionReferenceDto;
    /**
     * 
     * @type {TaxiVoucherStatusDto}
     * @memberof TaxiVoucherListItemDto
     */
    status: TaxiVoucherStatusDto;
    /**
     * 
     * @type {VersionDto}
     * @memberof TaxiVoucherListItemDto
     */
    version: VersionDto;
}

/**
 * Check if a given object implements the TaxiVoucherListItemDto interface.
 */
export function instanceOfTaxiVoucherListItemDto(value: object): boolean {
    if (!('id' in value)) return false;
    if (!('receiptNumber' in value)) return false;
    if (!('section' in value)) return false;
    if (!('status' in value)) return false;
    if (!('version' in value)) return false;
    return true;
}

export function TaxiVoucherListItemDtoFromJSON(json: any): TaxiVoucherListItemDto {
    return TaxiVoucherListItemDtoFromJSONTyped(json, false);
}

export function TaxiVoucherListItemDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): TaxiVoucherListItemDto {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'receiptNumber': json['receiptNumber'],
        'section': SectionReferenceDtoFromJSON(json['section']),
        'status': TaxiVoucherStatusDtoFromJSON(json['status']),
        'version': VersionDtoFromJSON(json['version']),
    };
}

export function TaxiVoucherListItemDtoToJSON(value?: TaxiVoucherListItemDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'receiptNumber': value['receiptNumber'],
        'section': SectionReferenceDtoToJSON(value['section']),
        'status': TaxiVoucherStatusDtoToJSON(value['status']),
        'version': VersionDtoToJSON(value['version']),
    };
}

