import { Stack } from '@chakra-ui/react';
import React from 'react';
import { FormProvider } from 'react-hook-form';
import PageForm from '../../../ui/page/page-form';
import { HelpSystemBranchProvider } from '../../help-system/common/help-system-context';
import EmailMailingRecipientControl, {
  AddEmailRecipient,
} from '../../mailing/mailing-recipient-editor/recipient-controls/email-mailing-recipient-control';
import PersonMailingRecipientControl, {
  AddPersonRecipient,
} from '../../mailing/mailing-recipient-editor/recipient-controls/person-mailing-recipient-control';
import useMailingRecipientEditor from '../../mailing/mailing-recipient-editor/use-mailing-recipient-editor';

interface CodeMailingRecipientEditorProps {
  mailingId: string;
}

type FormType = AddEmailRecipient & AddPersonRecipient;

export default function OfferMailingRecipientEditor({ mailingId }: CodeMailingRecipientEditorProps) {
  const initialFocusRef = React.useRef<HTMLInputElement>(null);
  const { form, onValid } = useMailingRecipientEditor<FormType>(mailingId, {
    personRecipients: [],
  });

  const handleValid = async ({ personRecipients, emailRecipients }: FormType) => {
    const recipients = [...(personRecipients || []), ...(emailRecipients || [])];

    await onValid({ recipients });
  };

  return (
    <HelpSystemBranchProvider pathPrefix="offer.mailing">
      <FormProvider {...form}>
        <PageForm onValid={handleValid} height="full" initialFocusRef={initialFocusRef}>
          <Stack spacing={4}>
            <PersonMailingRecipientControl mailingId={mailingId} />
            <EmailMailingRecipientControl />
          </Stack>
        </PageForm>
      </FormProvider>
    </HelpSystemBranchProvider>
  );
}
