import { HStack } from '@chakra-ui/react';
import React from 'react';
import { useController, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import invariant from 'tiny-invariant';
import { LandingPageDto } from '../../../api';
import RichTextFormControl from '../../../ui/form/rich-text-control/rich-text-form-control';

export default function TextModuleContentControl({ name }: { name: `modules.${number}` }) {
  const { t } = useTranslation(['landing_page']);
  const { control } = useFormContext<LandingPageDto>();
  const { field } = useController({ name, control });
  invariant(field.value.moduleType === 'text', 'Invalid module type');

  return (
    <HStack spacing={4} alignItems="stretch">
      <RichTextFormControl
        name={`${name}.content`}
        label={t('landing_page:textModule.label')}
        options={field.value.options}
        variant="landingPage"
      />
      <RichTextFormControl
        name={`${name}.contentEnglish`}
        label={t('landing_page:textModule.labelEnglish')}
        options={field.value.options}
        variant="landingPage"
      />
    </HStack>
  );
}
