import {
  Button,
  ButtonGroup,
  FormControl as BaseFormControl,
  FormErrorMessage,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
} from '@chakra-ui/react';
import { without } from 'lodash-es';
import React from 'react';
import { FormProvider, useController, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import invariant from 'tiny-invariant';
import { AddStaffToGroupDto, GroupDto, SectionReferenceDto, StaffToAddDto } from '../../../../../api';
import groupStaffApi from '../../../../../data-access/group-staff-api';
import ErrorMessage from '../../../../../ui/form/error-message';
import Form from '../../../../../ui/form/form';
import SubmitButton from '../../../../../ui/form/submit-button';
import useToast from '../../../../../ui/use-toast/use-toast';
import { HelpSystemBranchProvider } from '../../../../help-system/common/help-system-context';
import { groupStaffFetcher } from '../group-staff-queries';
import StaffForGroupSelectionControl from './staff-for-group-selection-control';
import { compareStaff, StaffInGroupSelectionViewer } from './staff-in-group-selection-viewer';

interface GroupStaffEditorDialogProps {
  isOpen: boolean;
  onClose: () => void;
  group: GroupDto;
}

export default function GroupStaffEditorDialog({ isOpen, onClose, group }: GroupStaffEditorDialogProps) {
  const initialFocusRef = React.useRef<HTMLInputElement>(null);

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl" closeOnOverlayClick={false} initialFocusRef={initialFocusRef}>
      <ModalOverlay />
      <ModalContent>
        <React.Suspense>
          <GroupStaffFormModal group={group} initialFocusRef={initialFocusRef} onClose={onClose} />
        </React.Suspense>
      </ModalContent>
    </Modal>
  );
}

interface GroupStaffFormModalProps {
  group: GroupDto;
  initialFocusRef?: React.RefObject<any>;
  onClose: () => void;
}

function GroupStaffFormModal({ group, initialFocusRef, onClose }: GroupStaffFormModalProps) {
  const { t } = useTranslation(['common', 'group']);

  const form = useForm<AddStaffToGroupDto>({
    mode: 'all',
    defaultValues: { staff: [] },
  });

  const showSuccessToast = useToast({
    id: 'add-staff-to-group-success-toast',
    status: 'success',
  });

  const handleValid = async (addStaffDto: AddStaffToGroupDto) => {
    invariant(group.id != null, 'Missing group.id');
    addStaffDto.staff.forEach((staff) => {
      staff.staff.belongsToSections = (staff.staff.belongsToSections as unknown as SectionReferenceDto[]).map(
        (bts) => bts.id,
      );
    });

    await groupStaffApi.addStaffToGroup({ addStaffToGroupDto: { ...addStaffDto, group: group } });

    showSuccessToast({
      title: t('group:connections.toastStaff.success.title'),
      description: t('group:connections.toastStaff.success.description', { count: addStaffDto.staff.length }),
    });

    onClose();

    await groupStaffFetcher.mutate();
  };

  const formIsDirty = Object.keys(form.formState.dirtyFields).length > 0;

  return (
    <HelpSystemBranchProvider pathPrefix="groupStaff.editor">
      <FormProvider {...form}>
        <Form<AddStaffToGroupDto> onValid={handleValid} initialFocusRef={initialFocusRef}>
          <ModalHeader>{t('group:connections.add_staff')}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <StaffSelectionForm group={group} initialFocusRef={initialFocusRef} />
          </ModalBody>

          <ModalFooter>
            <ButtonGroup spacing={4}>
              <Button onClick={onClose}>{t('common:action.abort')}</Button>
              <SubmitButton variant="primary" isDisabled={!formIsDirty}>
                {t('common:action.add')}
              </SubmitButton>
            </ButtonGroup>
          </ModalFooter>
        </Form>
      </FormProvider>
    </HelpSystemBranchProvider>
  );
}

export function StaffSelectionForm({
  group,
  initialFocusRef,
}: {
  group: GroupDto;
  initialFocusRef?: React.RefObject<any>;
}) {
  const { t } = useTranslation(['group']);

  const validateLength = (staff: StaffToAddDto[]) => {
    return staff.length > 0 || t('group:connections.at_least_one_person');
  };

  const { fieldState, field } = useController<AddStaffToGroupDto, 'staff'>({
    name: 'staff',
    rules: {
      validate: {
        length: validateLength,
      },
    },
  });

  const addEntry = (entry: StaffToAddDto) => field.onChange([...field.value, entry]);

  const removeEntry = (entry: StaffToAddDto) => {
    const newValue = without(field.value, entry);
    field.onChange(newValue);
  };

  return (
    <BaseFormControl isInvalid={fieldState.error != null}>
      <Stack padding={4} bgColor="background.highlight" borderRadius="base" spacing={4}>
        <Stack borderRadius="base" borderWidth="thin" borderColor="border.01" paddingTop={3}>
          <StaffForGroupSelectionControl
            group={group}
            staffAlreadyInGroup={field.value}
            onAdd={addEntry}
            initialFocusRef={initialFocusRef}
          />
        </Stack>
        <StaffInGroupSelectionViewer staff={field.value} onRemove={removeEntry} compare={compareStaff} />
      </Stack>
      <ErrorMessage as={FormErrorMessage} name="people" />
    </BaseFormControl>
  );
}
