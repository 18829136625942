import {
  Button,
  ButtonGroup,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
} from '@chakra-ui/react';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  CollectiveOrderConfirmationViewerDto,
  ManualPaymentDto,
  OrderConfirmationListItemDto,
  OrderConfirmationViewerDto,
} from '../../../api';
import collectiveOrderConfirmationApi from '../../../data-access/collective-order-confirmation-api';
import orderConfirmationApi from '../../../data-access/order-confirmation-api';
import { DisableableButton } from '../../../ui/disableable-button/disableable-button';
import Form from '../../../ui/form/form';
import SubmitButton from '../../../ui/form/submit-button';
import useToast from '../../../ui/use-toast/use-toast';
import { HelpSystemBranchProvider } from '../../help-system/common/help-system-context';
import { collectiveOrderConfirmationFetcher } from '../collective-order-confirmation/collective-order-confirmation-queries';
import { orderConfirmationFetcher } from '../order-confirmation-queries';
import RecordManualPaymentForm from './record-manual-payment-form';

interface RecordManualPaymentButtonProps extends React.HTMLAttributes<HTMLButtonElement> {
  orderConfirmation: OrderConfirmationViewerDto | OrderConfirmationListItemDto | CollectiveOrderConfirmationViewerDto;
  number: string;
}

export const RecordManualPaymentButton = React.forwardRef<HTMLButtonElement, RecordManualPaymentButtonProps>(
  ({ orderConfirmation, number, children, ...props }, ref) => {
    const { t } = useTranslation(['order_confirmation', 'common']);
    const {
      isOpen: recordManualPaymentDialogIsOpen,
      onOpen: onManualPaymentDialogOpen,
      onClose: onManualPaymentDialogClose,
    } = useDisclosure();

    const initialFocusRef = React.useRef<HTMLInputElement>(null);

    return (
      <>
        <DisableableButton
          {...props}
          ref={ref}
          onClick={onManualPaymentDialogOpen}
          aria-label={t('action.recordManualPayment.label')}
          isDisabled={
            recordManualPaymentDialogIsOpen ||
            orderConfirmation.status !== 'SEND' ||
            orderConfirmation.sumTotal.grossTotal == 0
          }
          disableReason={t('action.recordManualPayment.disabled')}
        >
          {children}
        </DisableableButton>
        <Modal
          isOpen={recordManualPaymentDialogIsOpen}
          onClose={onManualPaymentDialogClose}
          closeOnOverlayClick={false}
          initialFocusRef={initialFocusRef}
        >
          <ModalOverlay />
          <ModalContent>
            <RecordManualPaymentModalContent
              orderConfirmation={orderConfirmation}
              number={number}
              closeDialog={onManualPaymentDialogClose}
              initialFocusRef={initialFocusRef}
            />
          </ModalContent>
        </Modal>
      </>
    );
  },
);

interface ModalContentProps {
  orderConfirmation: OrderConfirmationViewerDto | OrderConfirmationListItemDto | CollectiveOrderConfirmationViewerDto;
  number: string;
  closeDialog: () => void;
  initialFocusRef: React.RefObject<any>;
}

function RecordManualPaymentModalContent({
  orderConfirmation,
  number,
  closeDialog,
  initialFocusRef,
}: ModalContentProps) {
  const { t } = useTranslation(['order_confirmation', 'common']);

  const form = useForm<ManualPaymentDto>({
    mode: 'all',
  });
  const showSendSuccessToast = useToast({
    id: 'order-confirmation-manual-payment-record-toast',
    status: 'success',
  });

  const isCollective = number.startsWith('S-AU');

  const handleValid = async (manualPayment: ManualPaymentDto) => {
    if (isCollective) {
      await collectiveOrderConfirmationApi.recordManualPaymentCollective({
        id: orderConfirmation.id,
        manualPaymentDto: manualPayment,
      });
      await collectiveOrderConfirmationFetcher.mutate();
    } else {
      await orderConfirmationApi.recordManualPayment({
        id: orderConfirmation.id,
        manualPaymentDto: manualPayment,
      });
      await orderConfirmationFetcher.mutate();
    }
    closeDialog();

    showSendSuccessToast({
      title: t('action.recordManualPayment.toast.title'),
      description: t('action.recordManualPayment.toast.description'),
    });
  };

  return (
    <HelpSystemBranchProvider pathPrefix="orderConfirmation.manualPayment">
      <FormProvider {...form}>
        <Form<ManualPaymentDto> onValid={handleValid} initialFocusRef={initialFocusRef}>
          <ModalHeader>{t('action.recordManualPayment.label')}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <RecordManualPaymentForm initialFocusRef={initialFocusRef} />
          </ModalBody>

          <ModalFooter>
            <ButtonGroup spacing={4}>
              <Button onClick={closeDialog}>{t('common:action.abort')}</Button>
              <SubmitButton variant="primary" isDisabled={!form.formState.isDirty}>
                {t('common:action.save')}
              </SubmitButton>
            </ButtonGroup>
          </ModalFooter>
        </Form>
      </FormProvider>
    </HelpSystemBranchProvider>
  );
}
