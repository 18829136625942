import {
  Alert,
  AlertDescription,
  AlertIcon,
  Button,
  ButtonGroup,
  Flex,
  HStack,
  Spacer,
  Stack,
  Tooltip,
} from '@chakra-ui/react';
import { areIntervalsOverlapping } from 'date-fns';
import { filter, isArray, isEqual } from 'lodash-es';
import React from 'react';
import { FieldPath, FieldValues, get, useController, useFormContext, useWatch } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import invariant from 'tiny-invariant';
import { v4 as randomUUID } from 'uuid';
import {
  OccupationDto,
  OccupationDtoConnectionTypeEnum,
  OccupationDtoStatusEnum,
  PersonDto,
  PersonReferenceDto,
  PersonStatusDto,
} from '../../../../api';
import CheckboxControl from '../../../../ui/form/checkbox-control';
import DateInputFormControl from '../../../../ui/form/date-input-control/date-input-form-control';
import { ElementFormModal, ElementTableControl, useElementForm } from '../../../../ui/form/element-control';
import AddElementButton from '../../../../ui/form/element-control/add-element-button';
import DeleteElementButton from '../../../../ui/form/element-control/delete-element-button';
import EditElementButton from '../../../../ui/form/element-control/edit-element-button';
import ElementContext from '../../../../ui/form/element-control/element-context';
import ValueSelectFormControl from '../../../../ui/form/select-control/value-select-form-control';
import useWatchChange from '../../../../ui/form/use-watch-change/use-watch-change';
import useDefinedContext from '../../../../util/context/use-defined-context/use-defined-context';
import now from '../../../../util/now';
import { LayoutType } from '../../../common/layout-type';
import useActiveEdition from '../../../edition/use-active-edition/use-active-edition';
import useOccupationColumns from '../../person-table-columns/occupation-columns';
import { calculateNextMainOccupation } from './calculate-next-occupation';
import CancelConnectionButton from './cancel-connection-button';
import ConnectionControl from './connection-control';
import MakeMainActivityButton, { canSetToMainActivity } from './make-main-occupation-button';
import RemoveMainOccupationButton from './remove-main-occupation-button';

interface NewOccupation extends OccupationDto {
  isNew?: boolean;
}

const STATUS_OPTIONS = [OccupationDtoStatusEnum.CONFIRMED, OccupationDtoStatusEnum.UNCONFIRMED];

export interface OccupationControlProps<T extends FieldValues> {
  path?: FieldPath<T>;
  layout: LayoutType;
  showAddButton?: boolean;
  highlightOverlapping?: boolean;
  fixMainOccupation?: boolean;
  showUnconfirmedStatus?: boolean;
}

export default function OccupationControl<T extends FieldValues>({
  path,
  layout,
  showAddButton = true,
  highlightOverlapping = false,
  fixMainOccupation = true,
  showUnconfirmedStatus = true,
}: OccupationControlProps<T>) {
  const prefixWithPath = <TPath extends string>(name: TPath) => (path != null ? (`${path}.${name}` as TPath) : name);
  const { t } = useTranslation('person');
  const { control } = useFormContext<PersonDto>();
  const person = useWatch({ control, name: path } as { control: typeof control });
  const fromPersonReference = person as PersonReferenceDto;
  const occupations = useController({ control, name: prefixWithPath('occupations') });
  const occupationColumns = useOccupationColumns(highlightOverlapping, showUnconfirmedStatus);

  const setAsMainActivity = (occupation: OccupationDto) => {
    if (canSetToMainActivity(occupation)) {
      const result = occupations.field.value!.map((o) => {
        return { ...o, mainActivity: isEqual(o, occupation) };
      });
      occupations.field.onChange(result);
    }
  };

  const removeAsMainActivity = (occupation: OccupationDto) => {
    const result = occupations.field.value!.map((o) => {
      return isEqual(o, occupation) ? { ...o, mainActivity: false } : o;
    });
    occupations.field.onChange(result);
  };

  const overlappingOccupations =
    occupations.field.value
      ?.map((o1) => {
        return occupations.field.value!.find((o2) =>
          areIntervalsOverlapping(
            { start: o1.dateRange.start, end: o1.dateRange.end ?? new Date(2100, 1, 1) },
            { start: o2.dateRange.start, end: o2.dateRange.end ?? new Date(2100, 1, 1) },
          ),
        );
      })
      .filter((v) => v != null) ?? [];

  useWatchChange<Required<PersonDto>>([prefixWithPath('occupations')], (data: PersonDto) => {
    if (!fixMainOccupation) {
      return;
    }
    const nextOccupations = get(data, prefixWithPath('occupations')) as OccupationDto[];
    let nextMainOccupation: OccupationDto | null = null;

    if (nextOccupations.length === 1) {
      nextMainOccupation = nextOccupations[0];
    } else if (nextOccupations.length > 1 && !nextOccupations.some((o) => o.mainActivity)) {
      const rightNow = new Date(now());
      nextMainOccupation = calculateNextMainOccupation(nextOccupations, rightNow);
    }

    if (nextMainOccupation != null && !nextMainOccupation.mainActivity) {
      const result = nextOccupations.map((o) => {
        return { ...o, mainActivity: o === nextMainOccupation };
      });
      occupations.field.onChange(result);
    }
  });

  return (
    <>
      {highlightOverlapping && overlappingOccupations?.length > 1 && (
        <Alert status="warning" mt={2}>
          <AlertIcon />
          <Stack>
            <AlertDescription>{t('merge_warning.occupation_conflict')}</AlertDescription>
          </Stack>
        </Alert>
      )}

      <ElementTableControl<PersonDto, OccupationDto>
        label={t('occupations.title')}
        name={prefixWithPath('occupations')}
        columns={occupationColumns}
        extraActionButton={
          <>
            {fixMainOccupation || <RemoveMainOccupationButton removeMainActivity={removeAsMainActivity} />}
            <MakeMainActivityButton
              label={t('occupations.mainActivity.action.makeMainActivity')}
              setMainActivity={setAsMainActivity}
            />
          </>
        }
        addButton={
          person?.status !== PersonStatusDto.ACTIVE || !showAddButton ? undefined : (
            <AddElementButton
              label={t('occupations.add')}
              formModal={<OccupationFormModal fromPersonReference={fromPersonReference} />}
            />
          )
        }
        editButton={
          <EditElementButton
            label={t('occupations.edit')}
            formModal={<OccupationFormModal fromPersonReference={fromPersonReference} isEdit />}
          />
        }
        deleteButton={<EndOrDeleteButton fromPersonReference={fromPersonReference} />}
        layout={layout}
        validate={(value) => {
          invariant(isArray(value));
          if (value.length === 0) {
            return undefined;
          }
          if (filter(value, (occupation) => (occupation as OccupationDto).mainActivity).length !== 1) {
            return t('occupations.mainActivity.validationError');
          }
          return undefined;
        }}
      />
    </>
  );
}

interface EndOrDeleteButtonProps {
  fromPersonReference: PersonReferenceDto;
}

function EndOrDeleteButton({ fromPersonReference }: EndOrDeleteButtonProps) {
  const { t } = useTranslation('person');
  const { element } = useDefinedContext(ElementContext);

  // jaj, lh: Show delete button for unsaved connections and the cancel dialog for saved ones.
  return (element as NewOccupation).isNew ? (
    <DeleteElementButton<OccupationDto>
      label={t('occupations.delete')}
      renderDeleteMessage={(element) => (
        <Trans t={t} i18nKey="occupations.delete_message" values={{ jobTitle: element.jobTitle }} />
      )}
    />
  ) : (
    <CancelConnectionButton label={t('connections.end_connection')} fromPersonReference={fromPersonReference} />
  );
}

export enum OccupationType {
  WITHOUT_COMPANY = 'WITHOUT',
  WITH_COMPANY = 'WITH',
}

interface OccupationFormModalProps {
  fromPersonReference: PersonReferenceDto;
  isEdit?: boolean;
}

function OccupationFormModal({ fromPersonReference, isEdit }: OccupationFormModalProps) {
  const { t } = useTranslation('person');
  const { t: tCommon } = useTranslation('common');
  const { element: occupation, onSubmit } = useElementForm<OccupationDto>();
  const initialFocusRef = React.useRef<HTMLInputElement>(null);
  const { setValue } = useFormContext<NewOccupation>();
  const [occupationType, setOccupationType] = React.useState(
    occupation?.connectionType === OccupationDtoConnectionTypeEnum.FREELANCER
      ? OccupationType.WITHOUT_COMPANY
      : OccupationType.WITH_COMPANY,
  );

  const { register } = useFormContext<OccupationDto>();
  register('mainActivity');
  register('id');
  register('companyAdmin');

  const activeEdition = useActiveEdition();

  const withoutCompany = () => {
    setOccupationType(OccupationType.WITHOUT_COMPANY);
    if (occupation != null) {
      occupation.connectionType = OccupationDtoConnectionTypeEnum.FREELANCER;
    }
  };

  const withCompany = () => {
    setOccupationType(OccupationType.WITH_COMPANY);
    if (occupation != null) {
      occupation.connectionType = OccupationDtoConnectionTypeEnum.EMPLOYEE;
    }
  };

  const prefillToday = () =>
    setValue(
      'dateRange',
      { start: new Date(now()), end: undefined },
      {
        shouldValidate: true,
        shouldDirty: true,
      },
    );

  const prefillActiveEdition = () =>
    setValue('dateRange', activeEdition.dateRange, { shouldValidate: true, shouldDirty: true });
  const start = useWatch<OccupationDto>({ name: 'dateRange.start' }) as Date;
  const isNew = occupation == null || (occupation as NewOccupation).isNew;

  const handleSubmit = (occupation: OccupationDto) => {
    // jaj, lh: Add flag to be able to show different delete dialogs for unsaved connections.
    onSubmit({
      ...occupation,
      connectionType:
        occupationType === OccupationType.WITH_COMPANY
          ? OccupationDtoConnectionTypeEnum.EMPLOYEE
          : OccupationDtoConnectionTypeEnum.FREELANCER,
      isNew,
    } as NewOccupation);
  };

  const withCompanyTab =
    (occupation == null && occupationType === OccupationType.WITH_COMPANY) ||
    occupation?.connectionType === OccupationDtoConnectionTypeEnum.EMPLOYEE;

  const withoutCompanyTab =
    (occupation == null && occupationType === OccupationType.WITHOUT_COMPANY) ||
    occupation?.connectionType === OccupationDtoConnectionTypeEnum.FREELANCER;

  return (
    <ElementFormModal<OccupationDto>
      onSubmit={handleSubmit}
      element={occupation}
      initialFocusRef={initialFocusRef}
      defaultElement={{
        connectionType: OccupationDtoConnectionTypeEnum.FREELANCER,
        dateRange: { start: new Date(now()) },
        status: OccupationDtoStatusEnum.CONFIRMED,
        id: randomUUID(),
        formats: [],
        companyAdmin: false,
      }}
    >
      <Flex alignItems="center" width="100%" pb={4}>
        <ButtonGroup isAttached variant="outline" width="100%">
          <SelectableButton isSelected={withCompanyTab} isDisabled={isEdit} onClick={withCompany}>
            {t('occupations.employee_title')}
          </SelectableButton>
          <SelectableButton isSelected={withoutCompanyTab} isDisabled={isEdit} onClick={withoutCompany}>
            {t('occupations.freelancer_title')}
          </SelectableButton>
        </ButtonGroup>
      </Flex>
      <ConnectionControl
        fromReference={fromPersonReference}
        initialFocusRef={initialFocusRef}
        toReferenceEditable={!isEdit}
        showCompany={
          occupationType === OccupationType.WITH_COMPANY ||
          occupation?.connectionType === OccupationDtoConnectionTypeEnum.EMPLOYEE
        }
      />
      <Stack
        spacing={4}
        sx={{
          marginTop: 4,
          borderWidth: 1,
          borderRadius: 'base',
          padding: 4,
          borderColor: 'border.01',
        }}
      >
        <HStack spacing={2}>
          <span>{tCommon('prefill')}: </span> <Spacer />
          <Tooltip label={t('connections.as_of_today_tooltip')}>
            <Button size="sm" onClick={prefillToday}>
              {t('connections.as_of_today')}
            </Button>
          </Tooltip>
          <Tooltip label={t('connections.active_edition_tooltip')}>
            <Button size="sm" onClick={prefillActiveEdition}>
              {t('connections.active_edition')}
            </Button>
          </Tooltip>
        </HStack>
        <HStack alignItems="start">
          <DateInputFormControl<OccupationDto>
            name="dateRange.start"
            isRequired
            deps={['dateRange.end']}
            label={t('connections.date_range_start')}
          />
          <DateInputFormControl<OccupationDto>
            name="dateRange.end"
            deps={['dateRange.start']}
            label={t('connections.date_range_end')}
            referenceDate={start}
            min={{
              value: start,
              message: tCommon('validation_error.date_end_before_date_start'),
            }}
          />
        </HStack>
        <ValueSelectFormControl<OccupationDtoStatusEnum>
          name="status"
          label={t('occupations.status')}
          options={STATUS_OPTIONS}
          renderLabel={(value) => t(`occupations.statusOptions.${value}`)}
          defaultValue={OccupationDtoStatusEnum.CONFIRMED}
          isDisabled
          isRequired
        />
        <CheckboxControl<OccupationDto> name="publishJob" label={t('occupations.publishJob')} />
      </Stack>
    </ElementFormModal>
  );
}

function SelectableButton({
  isSelected,
  onClick,
  isDisabled,
  children,
}: {
  isSelected: boolean;
  onClick: () => void;
  isDisabled?: boolean;
  children: React.ReactNode;
}) {
  return (
    <Button
      sx={{
        width: '50%',
      }}
      variant={isSelected ? 'primary' : 'outline'}
      onClick={onClick}
      isDisabled={isDisabled}
    >
      {children}
    </Button>
  );
}
