import { chakra, ChakraProps, Heading, Link, Text, useMultiStyleConfig } from '@chakra-ui/react';
import { SystemStyleObject } from '@chakra-ui/styled-system';
import React, { Fragment, ReactElement } from 'react';
import { BlockChildDto, BlockElementDto, InlineElementDto, MarkElementDto } from '../../api';

export default function RichTextRenderer({ text }: { text: BlockElementDto[] | undefined }) {
  const styles = useMultiStyleConfig('RichText', { variant: 'berlinalx' });

  return <chakra.div __css={styles.container}>{renderBlockChildren(text, styles)}</chakra.div>;
}

function renderBlockChildren(
  children: (BlockElementDto | BlockChildDto)[] | undefined,
  styles: Record<string, SystemStyleObject>,
) {
  const getCommonBlockProps = (child: BlockElementDto | BlockChildDto) => {
    return {
      textAlign: 'align' in child ? (child.align as 'center' | 'justify' | 'left' | 'right') : undefined,
    } satisfies ChakraProps;
  };

  const renderBlockChild = (child: BlockElementDto | BlockChildDto) => {
    switch (child.type) {
      case 'paragraph':
        return <Text {...getCommonBlockProps(child)}>{renderBlockChildren(child.children, styles)}</Text>;
      case 'heading1':
        return (
          <Heading __css={styles.heading1} {...getCommonBlockProps(child)}>
            {renderBlockChildren(child.children, styles)}
          </Heading>
        );
      case 'heading2':
        return (
          <Heading __css={styles.heading1} {...getCommonBlockProps(child)}>
            {renderBlockChildren(child.children, styles)}
          </Heading>
        );
      case 'unorderedList':
        return (
          <chakra.ul __css={styles.unorderedList} {...getCommonBlockProps(child)}>
            {renderBlockChildren(child.children, styles)}
          </chakra.ul>
        );
      case 'orderedList':
        return (
          <chakra.ol __css={styles.orderedList} {...getCommonBlockProps(child)}>
            {renderBlockChildren(child.children, styles)}
          </chakra.ol>
        );
      case 'listItem':
        return <li {...getCommonBlockProps(child)}>{renderBlockChildren(child.children, styles)}</li>;
      default:
        return renderInlineElement(child, styles);
    }
  };

  return (
    <>{children?.map((blockElement, index) => <Fragment key={index}>{renderBlockChild(blockElement)}</Fragment>)}</>
  );
}

function renderInlineElement(child: InlineElementDto, styles: Record<string, SystemStyleObject>) {
  switch (child.type) {
    case 'link':
      return (
        <Link __css={styles.link} href={child.url}>
          {child.children != null
            ? renderBlockChildren(child.children as BlockChildDto[] | undefined, styles)
            : undefined}
        </Link>
      );
    default:
      return renderMarkElement(child, styles);
  }
}

function renderMarkElement(child: MarkElementDto, styles: Record<string, SystemStyleObject>) {
  let text: ReactElement = (
    <>
      {child.text?.split('\n').map((part, index) => (
        <Fragment key={index}>
          {index > 0 && <br />}
          {part}
        </Fragment>
      ))}
    </>
  );

  if (child.italic) {
    text = <chakra.em __css={styles.italic}>{text}</chakra.em>;
  }

  if (child.bold) {
    text = <chakra.strong __css={styles.strong}>{text}</chakra.strong>;
  }

  if (child.small) {
    text = <chakra.small __css={styles.italic}>{text}</chakra.small>;
  }

  return text;
}
