import {
  ButtonProps,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Heading,
  IconButton,
  Tooltip,
  useDisclosure,
} from '@chakra-ui/react';
import { faInfoCircle } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import RichTextRenderer from '../../../ui/rich-text/rich-text-renderer';
import { HelpSystemBranchProvider } from './help-system-context';
import HelpText from './help-text';

export interface ContextHelpButtonProps extends ButtonProps {
  path: string;
}

export default function HelperContextButton({ path, ...props }: ContextHelpButtonProps) {
  const { t } = useTranslation(['help_system', 'common']);
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <HelpSystemBranchProvider pathPrefix={path}>
      <HelpText path="context">
        {({ helpText }) => (
          <>
            <Tooltip label={t('help_system:contextHelp')}>
              <IconButton
                {...props}
                variant="ghost"
                className="group"
                icon={<FontAwesomeIcon icon={faInfoCircle} />}
                aria-label={t('help_system:help')}
                onClick={onOpen}
              />
            </Tooltip>
            <Drawer isOpen={isOpen} placement="right" onClose={onClose} size="md">
              <DrawerOverlay />
              <DrawerContent>
                <DrawerHeader display="flex" alignItems="center" justifyContent="space-between">
                  <Heading as="h2" size="md">
                    {t('help_system:contextHelp')}
                  </Heading>
                </DrawerHeader>
                <DrawerBody>
                  <RichTextRenderer text={helpText.text} />
                </DrawerBody>
              </DrawerContent>
            </Drawer>
          </>
        )}
      </HelpText>
    </HelpSystemBranchProvider>
  );
}
