import { isRouteErrorResponse } from 'react-router-dom';
import { ResponseError } from '../../api';
import BadRequest from './bad-request';
import CustomError from './custom-error';
import Forbidden from './forbidden';
import NotFound from './not-found';

interface ErrorPageProps {
  error: unknown;
}

const chunkLoadingErrors = ['Failed to fetch dynamically imported module', 'Unable to preload CSS'];

export default function ErrorPage({ error }: ErrorPageProps) {
  const response = error instanceof ResponseError ? error.response : error;

  console.error(error);

  if (isRouteErrorResponse(response) || response instanceof Response) {
    if (response.status === 403) {
      return <Forbidden />;
    }

    if (response.status === 404) {
      return <NotFound />;
    }

    if (response.status === 400) {
      return <BadRequest />;
    }
  }

  // Hard-reload the page when chunk load errors match the navigation error
  if (error instanceof TypeError && chunkLoadingErrors.some((e) => error.message.includes(e))) {
    window.location.reload();
  }

  return <CustomError />;
}
