import { Stack } from '@chakra-ui/react';
import { isEmpty } from 'lodash-es';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { MailingPictureModuleDataDto } from '../../../../api';
import { AttachmentImage } from '../../../../ui/attachments/attachment-image';
import { FileSuffixes, FileType } from '../../../../ui/attachments/upload/file-type';
import { DataTableColumn } from '../../../../ui/data-table';
import CheckboxControl from '../../../../ui/form/checkbox-control';
import { ElementFormModal, ElementTableControl, useElementForm } from '../../../../ui/form/element-control';
import AddElementButton from '../../../../ui/form/element-control/add-element-button';
import DeleteElementButton from '../../../../ui/form/element-control/delete-element-button';
import EditElementButton from '../../../../ui/form/element-control/edit-element-button';
import ImageControl from '../../../../ui/form/image-control/image-control';
import InputFormControl from '../../../../ui/form/input-form-control';
import Optional from '../../../../ui/optional/optional';
import Translate from '../../../../util/translate/translate';
import { validateUrl } from '../../../../util/url/url-validation';

interface PictureMailingModuleControlProps {
  name: string;
}

export default function PictureMailingModuleControl({ name }: PictureMailingModuleControlProps) {
  const { t } = useTranslation('mailing');

  const columns: DataTableColumn<MailingPictureModuleDataDto>[] = [
    {
      key: 'image',
      name: '',
      renderCell: (data) => {
        return <AttachmentImage metadata={data.image} />;
      },
    },
    {
      key: 'fileName',
      name: <Translate ns="mailing" i18nKey="picture_module.file_name" />,
      renderCell: (data) => <Optional>{data.image.originalFileName}</Optional>,
    },
    {
      key: 'caption',
      name: <Translate ns="mailing" i18nKey="picture_module.caption.label" />,
      renderCell: (data) => <Optional>{data.caption}</Optional>,
    },
    {
      key: 'linkTarget',
      name: <Translate ns="mailing" i18nKey="picture_module.link" />,
      renderCell: (data) => <Optional>{data.linkTarget}</Optional>,
    },
  ];

  return (
    <Stack>
      <ElementTableControl
        label={t('picture_module.label')}
        maxElements={2}
        addButton={<AddElementButton label={t('picture_module.add_picture')} formModal={<PictureFormModal />} />}
        editButton={<EditElementButton label={t('picture_module.edit_picture')} formModal={<PictureFormModal />} />}
        deleteButton={
          <DeleteElementButton
            label={t('picture_module.delete_picture')}
            renderDeleteMessage={() => <Trans t={t} i18nKey="picture_module.delete_message" />}
          />
        }
        name={`${name}.pictures`}
        columns={columns}
      />
      <CheckboxControl name={`${name}.cropImages`} label={t('picture_module.crop')} />
    </Stack>
  );
}

function PictureFormModal() {
  const { t } = useTranslation('mailing');
  const { t: tCommon } = useTranslation('common');
  const { t: tAttachment } = useTranslation('attachment');

  const { element: pictureData, onSubmit } = useElementForm<MailingPictureModuleDataDto>();

  const initialFocusRef = React.useRef<HTMLInputElement>(null);

  const maxFileSizeInMB = 5;

  const minPhotoDimension = { width: 400, height: 350 };
  const maxPhotoDimension = { width: 4000, height: 3500 };
  // Mail from Manja on the 23th of December 2022 around 3pm. Wish for webp to be available whereever jpeg is available.
  const acceptFileTypes = [FileType.JPEG, FileType.GIF, FileType.PNG, FileType.WEBP];

  const handleAddPicture = async (MailingPictureModuleDataDto: MailingPictureModuleDataDto) => {
    cleanupMailingPictureModuleDataDto(MailingPictureModuleDataDto);
    onSubmit(MailingPictureModuleDataDto);
  };

  function cleanupMailingPictureModuleDataDto(MailingPictureModuleDataDto: MailingPictureModuleDataDto) {
    MailingPictureModuleDataDto.linkTarget = isEmpty(MailingPictureModuleDataDto.linkTarget)
      ? undefined
      : MailingPictureModuleDataDto.linkTarget;
  }

  return (
    <ElementFormModal<MailingPictureModuleDataDto>
      onSubmit={handleAddPicture}
      element={pictureData}
      initialFocusRef={initialFocusRef}
      size="2xl"
    >
      <Stack spacing={4}>
        <ImageControl<MailingPictureModuleDataDto>
          name="image"
          label={t('picture_module.image')}
          maxFileSizeInMB={maxFileSizeInMB}
          acceptFileTypes={acceptFileTypes}
          namespace="mailing_picture"
          isRequired
          helperText={
            tAttachment('helper_text.size_and_accepted_types', {
              size_mb: maxFileSizeInMB,
              acceptedFileTypes: acceptFileTypes.flatMap((type) => FileSuffixes[type]),
            }) +
            ' ' +
            tAttachment('helper_text.photo_dimension', {
              minWidth: minPhotoDimension.width,
              minHeight: minPhotoDimension.height,
              maxWidth: maxPhotoDimension.width,
              maxHeight: maxPhotoDimension.height,
            })
          }
        />
        <InputFormControl
          label={t('picture_module.caption.label')}
          ariaLabel={t('picture_module.caption.label')}
          name="caption"
          maxLength={100}
        />

        <InputFormControl
          label={t('picture_module.link')}
          ariaLabel={t('picture_module.link')}
          name="linkTarget"
          validate={(value) => validateUrl(tCommon, value)}
        />
      </Stack>
    </ElementFormModal>
  );
}
