import { Stack } from '@chakra-ui/react';
import React from 'react';
import { FormProvider } from 'react-hook-form';
import PageForm from '../../../ui/page/page-form';
import { HelpSystemBranchProvider } from '../../help-system/common/help-system-context';
import { AddEmailRecipient } from '../../mailing/mailing-recipient-editor/recipient-controls/email-mailing-recipient-control';
import ListMailingRecipientControl, {
  AddListRecipient,
} from '../../mailing/mailing-recipient-editor/recipient-controls/list-mailing-recipient-control';
import PersonMailingRecipientControl, {
  AddPersonRecipient,
} from '../../mailing/mailing-recipient-editor/recipient-controls/person-mailing-recipient-control';
import useMailingRecipientEditor from '../../mailing/mailing-recipient-editor/use-mailing-recipient-editor';

interface CodeMailingRecipientEditorProps {
  mailingId: string;
}

type FormType = AddEmailRecipient & AddPersonRecipient & AddListRecipient;

export default function CodeMailingRecipientEditor({ mailingId }: CodeMailingRecipientEditorProps) {
  const initialFocusRef = React.useRef<HTMLInputElement>(null);
  const { form, onValid } = useMailingRecipientEditor<FormType>(mailingId, {
    personRecipients: [],
  });

  const handleValid = async ({ personRecipients, listRecipient }: FormType) => {
    const recipients = listRecipient == null ? [...personRecipients] : [...personRecipients, listRecipient];

    await onValid({ recipients });
  };

  return (
    <HelpSystemBranchProvider pathPrefix="code.mailing">
      <FormProvider {...form}>
        <PageForm onValid={handleValid} height="full" initialFocusRef={initialFocusRef}>
          <Stack spacing={4}>
            <ListMailingRecipientControl mailingId={mailingId} />
            <PersonMailingRecipientControl mailingId={mailingId} />
          </Stack>
        </PageForm>
      </FormProvider>
    </HelpSystemBranchProvider>
  );
}
