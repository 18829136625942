// @see https://github.com/preactjs/signals/blob/main/packages/react/CHANGELOG.md#200
import '@preact/signals-react/auto';
import { ChakraProvider } from '@chakra-ui/react';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { HelmetProvider } from 'react-helmet-async';
import { I18nextProvider } from 'react-i18next';
import { RouterProvider } from 'react-router-dom';
import invariant from 'tiny-invariant';
import { HelpSystemRootProvider } from './feature/help-system/common/help-system-context';
import i18n from './i18n';
import registry from './registry';
import router from './router';
import theme from './theme';
import PageSpinner from './ui/page/page-spinner';
import { PluginRegistryProvider } from './util/plugin/use-plugins';

// @ts-expect-error todo
window.GlobalFetch = window;

const container = document.getElementById('root');
invariant(container != null);

const root = createRoot(container);
root.render(
  <React.StrictMode>
    <ChakraProvider theme={theme}>
      <I18nextProvider i18n={i18n()}>
        <PluginRegistryProvider value={registry}>
          <HelpSystemRootProvider inspectEnabled debug={process.env.NODE_ENV !== 'production'}>
            <HelmetProvider>
              <RouterProvider router={router} fallbackElement={<PageSpinner />} />
            </HelmetProvider>
          </HelpSystemRootProvider>
        </PluginRegistryProvider>
      </I18nextProvider>
    </ChakraProvider>
  </React.StrictMode>,
);
