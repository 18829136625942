/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  FailureDto,
  HelpTextDto,
  HelpTextPageDto,
  PageableDto,
} from '../models/index';
import {
    FailureDtoFromJSON,
    FailureDtoToJSON,
    HelpTextDtoFromJSON,
    HelpTextDtoToJSON,
    HelpTextPageDtoFromJSON,
    HelpTextPageDtoToJSON,
    PageableDtoFromJSON,
    PageableDtoToJSON,
} from '../models/index';

export interface CreateHelpTextRequest {
    helpTextDto: HelpTextDto;
}

export interface DeleteHelpTextRequest {
    id: string;
}

export interface FetchHelpTextsRequest {
    namespace: string;
}

export interface SearchHelpTextsRequest {
    filter?: Array<string>;
    pageable?: PageableDto;
}

export interface UpdateHelpTextRequest {
    id: string;
    helpTextDto: HelpTextDto;
}

/**
 * 
 */
export class HelpSystemApi extends runtime.BaseAPI {

    /**
     * Create a new help text.
     */
    async createHelpTextRaw(requestParameters: CreateHelpTextRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['helpTextDto'] == null) {
            throw new runtime.RequiredError(
                'helpTextDto',
                'Required parameter "helpTextDto" was null or undefined when calling createHelpText().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/help-system/help-texts`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: HelpTextDtoToJSON(requestParameters['helpTextDto']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Create a new help text.
     */
    async createHelpText(requestParameters: CreateHelpTextRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.createHelpTextRaw(requestParameters, initOverrides);
    }

    /**
     * Delete help text
     */
    async deleteHelpTextRaw(requestParameters: DeleteHelpTextRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling deleteHelpText().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/help-system/help-texts/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete help text
     */
    async deleteHelpText(requestParameters: DeleteHelpTextRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteHelpTextRaw(requestParameters, initOverrides);
    }

    /**
     * Fetch all help text paths.
     */
    async fetchHelpTextPathsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<string>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/help-system/help-texts/paths`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * Fetch all help text paths.
     */
    async fetchHelpTextPaths(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<string>> {
        const response = await this.fetchHelpTextPathsRaw(initOverrides);
        return await response.value();
    }

    /**
     * Get all help texts for a given namespace including the help text paths without an existing text
     */
    async fetchHelpTextsRaw(requestParameters: FetchHelpTextsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<HelpTextDto>>> {
        if (requestParameters['namespace'] == null) {
            throw new runtime.RequiredError(
                'namespace',
                'Required parameter "namespace" was null or undefined when calling fetchHelpTexts().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/help-system/help-texts/{namespace}`.replace(`{${"namespace"}}`, encodeURIComponent(String(requestParameters['namespace']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(HelpTextDtoFromJSON));
    }

    /**
     * Get all help texts for a given namespace including the help text paths without an existing text
     */
    async fetchHelpTexts(requestParameters: FetchHelpTextsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<HelpTextDto>> {
        const response = await this.fetchHelpTextsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Search for existing help texts.
     */
    async searchHelpTextsRaw(requestParameters: SearchHelpTextsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<HelpTextPageDto>> {
        const queryParameters: any = {};

        if (requestParameters['filter'] != null) {
            queryParameters['filter'] = requestParameters['filter'];
        }

        if (requestParameters['pageable'] != null) {
            queryParameters['pageable'] = requestParameters['pageable'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/help-system/help-texts`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => HelpTextPageDtoFromJSON(jsonValue));
    }

    /**
     * Search for existing help texts.
     */
    async searchHelpTexts(requestParameters: SearchHelpTextsRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<HelpTextPageDto> {
        const response = await this.searchHelpTextsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Fetch the template for creating a new help text.
     */
    async templateHelpTextRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<HelpTextDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/help-system/help-texts/template`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => HelpTextDtoFromJSON(jsonValue));
    }

    /**
     * Fetch the template for creating a new help text.
     */
    async templateHelpText(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<HelpTextDto> {
        const response = await this.templateHelpTextRaw(initOverrides);
        return await response.value();
    }

    /**
     * Update the existing help text.
     */
    async updateHelpTextRaw(requestParameters: UpdateHelpTextRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling updateHelpText().'
            );
        }

        if (requestParameters['helpTextDto'] == null) {
            throw new runtime.RequiredError(
                'helpTextDto',
                'Required parameter "helpTextDto" was null or undefined when calling updateHelpText().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/help-system/help-texts/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: HelpTextDtoToJSON(requestParameters['helpTextDto']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Update the existing help text.
     */
    async updateHelpText(requestParameters: UpdateHelpTextRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updateHelpTextRaw(requestParameters, initOverrides);
    }

}
