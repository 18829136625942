import React from 'react';
import { FieldPath, FieldValues } from 'react-hook-form';
import FormControl from '../form-control';
import ValueAsyncSelectControl, { ValueAsyncSelectControlProps } from './value-async-select-control';

export interface ValueAsyncSelectFormControlProps<
  TOption,
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> extends ValueAsyncSelectControlProps<TOption, TFieldValues, TName> {
  helperText?: React.ReactNode;
  label: string;
}

function ValueAsyncSelectFormControl<
  TOption,
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>(
  {
    name,
    label,
    helperText,
    isRequired,
    size,
    isDisabled,
    ...props
  }: ValueAsyncSelectFormControlProps<TOption, TFieldValues, TName>,
  ref: React.ForwardedRef<HTMLElement>,
) {
  return (
    <FormControl
      label={label}
      name={name}
      isRequired={isRequired}
      helperText={helperText}
      size={size}
      isDisabled={isDisabled}
    >
      <ValueAsyncSelectControl
        label={label}
        name={name}
        isRequired={isRequired}
        ref={ref}
        size={size}
        isDisabled={isDisabled}
        {...props}
      />
    </FormControl>
  );
}

export default React.forwardRef(ValueAsyncSelectFormControl);
