/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { TaxiVoucherDto } from './TaxiVoucherDto';
import {
    TaxiVoucherDtoFromJSON,
    TaxiVoucherDtoFromJSONTyped,
    TaxiVoucherDtoToJSON,
} from './TaxiVoucherDto';

/**
 * 
 * @export
 * @interface CreateTaxiVoucherDto
 */
export interface CreateTaxiVoucherDto {
    /**
     * 
     * @type {TaxiVoucherDto}
     * @memberof CreateTaxiVoucherDto
     */
    taxiVoucher: TaxiVoucherDto;
    /**
     * 
     * @type {number}
     * @memberof CreateTaxiVoucherDto
     */
    amount: number;
}

/**
 * Check if a given object implements the CreateTaxiVoucherDto interface.
 */
export function instanceOfCreateTaxiVoucherDto(value: object): boolean {
    if (!('taxiVoucher' in value)) return false;
    if (!('amount' in value)) return false;
    return true;
}

export function CreateTaxiVoucherDtoFromJSON(json: any): CreateTaxiVoucherDto {
    return CreateTaxiVoucherDtoFromJSONTyped(json, false);
}

export function CreateTaxiVoucherDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateTaxiVoucherDto {
    if (json == null) {
        return json;
    }
    return {
        
        'taxiVoucher': TaxiVoucherDtoFromJSON(json['taxiVoucher']),
        'amount': json['amount'],
    };
}

export function CreateTaxiVoucherDtoToJSON(value?: CreateTaxiVoucherDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'taxiVoucher': TaxiVoucherDtoToJSON(value['taxiVoucher']),
        'amount': value['amount'],
    };
}

