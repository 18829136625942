import React from 'react';
import { FieldPath, FieldValues } from 'react-hook-form/dist/types';
import { useTranslation } from 'react-i18next';
import FormControl, { FormControlProps } from '../form-control';
import DateInputControl, { DateInputControlProps } from './date-input-control';

export interface DateInputFormControlProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> extends Omit<DateInputControlProps<TFieldValues, TName>, 'required' | 'label'> {
  label: FormControlProps<TFieldValues>['label'];
  isReadOnly?: FormControlProps<TFieldValues>['isReadOnly'];
  helperText?: FormControlProps<TFieldValues>['helperText'];
  isRequired?: FormControlProps<TFieldValues>['isRequired'];
  referenceDate?: Date;
  requiredLabel?: string;
  onBlur?: FormControlProps<TFieldValues>['onBlur'];
  onPickerToggle?(isOpen: boolean): void;
}

function DateInputFormControl<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>(
  {
    isRequired,
    name,
    label,
    isDisabled,
    isReadOnly,
    helperText,
    size,
    referenceDate,
    requiredLabel,
    onBlur,
    onPickerToggle,
    ...props
  }: DateInputFormControlProps<TFieldValues, TName>,
  ref: React.ForwardedRef<HTMLInputElement>,
) {
  const { t } = useTranslation('common');
  return (
    <FormControl<TFieldValues>
      isRequired={isRequired}
      name={name}
      label={label}
      isDisabled={isDisabled}
      isReadOnly={isReadOnly}
      helperText={helperText}
      onBlur={onBlur}
      size={size}
    >
      <DateInputControl
        {...props}
        name={name}
        referenceDate={referenceDate}
        required={
          isRequired ? (requiredLabel ? requiredLabel : t('validation_error.required', { field: label })) : undefined
        }
        isDisabled={isDisabled}
        size={size}
        ref={ref}
        onPickerToggle={onPickerToggle}
      />
    </FormControl>
  );
}

export default React.forwardRef(DateInputFormControl);
