import isHotkey from 'is-hotkey';
import { createContext, ReactNode, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { HelpTextDto } from '../../../api';
import useFetcher from '../../../util/swr/use-fetcher';
import useCallbackRef from '../../../util/use-callback-ref/use-callback-ref';
import { fetchHelpTexts } from '../help-system-queries';

export interface HelpSystemRootContextProps {
  inspect?: boolean;
  inspectEnabled?: boolean;
  onMissingPath?: (path: string) => void;
}

export interface HelpSystemBranchContextProps {
  helpTexts: Map<string, HelpTextDto>;
  pathPrefix?: string;
}

export const HelpSystemRootContext = createContext<HelpSystemRootContextProps | undefined>(undefined);
export const HelpSystemBranchContext = createContext<HelpSystemBranchContextProps | undefined>(undefined);

/**
 * Create a new help system branch context. Takes care to inherit the parent help system context properties
 * correctly.
 */
function createHelpSystemBranchContext({
  parent,
  helpTexts,
  pathPrefix,
}: Omit<HelpSystemBranchContextProps, 'helpTexts'> & {
  parent?: HelpSystemBranchContextProps;
  helpTexts?: HelpTextDto[];
}): HelpSystemBranchContextProps {
  return {
    // Append parent path prefix if available.
    pathPrefix:
      pathPrefix != null
        ? parent?.pathPrefix != null
          ? `${parent.pathPrefix}.${pathPrefix}`
          : `${pathPrefix}`
        : parent?.pathPrefix,
    // Merge help texts with parent if available. Transform list of help texts to a faster map of
    // help texts mapped by path.
    helpTexts:
      helpTexts != null
        ? new Map<string, HelpTextDto>([
            ...(parent?.helpTexts ?? []),
            ...(helpTexts ?? []).map((helpText) => [helpText.path, helpText] as const),
          ])
        : parent?.helpTexts ?? new Map(),
  };
}

/**
 * Create root help system root context. Embed developer tools (inspector, logging for missing paths)
 */
export function HelpSystemRootProvider({
  children,
  debug,
  inspectEnabled,
}: {
  children?: ReactNode;
  debug?: boolean;
  inspectEnabled?: boolean;
}) {
  const handleMissingPath = useHelpSystemMissingPath({ enabled: debug });
  const inspect = useHelpSystemInspect({ enabled: inspectEnabled });
  const context = useMemo(
    () => ({ inspect, inspectEnabled, onMissingPath: handleMissingPath }),
    [inspect, inspectEnabled, handleMissingPath],
  );

  return <HelpSystemRootContext.Provider value={context}>{children}</HelpSystemRootContext.Provider>;
}

function useHelpSystemMissingPath({ enabled }: { enabled?: boolean }) {
  const [missingPaths] = useState(() => new Set<string>());
  const timeoutRef = useRef<ReturnType<typeof setTimeout>>();
  const collectedPathsRef = useRef(new Set<string>());
  const handleMissingPath = useCallbackRef((path: string) => {
    if (!enabled) {
      return;
    }

    if (!missingPaths.has(path)) {
      clearTimeout(timeoutRef.current);
      timeoutRef.current = setTimeout(() => {
        console.error('Missing help text paths:', Array.from(collectedPathsRef.current.values()).sort());
        collectedPathsRef.current.clear();
      }, 100);
      collectedPathsRef.current.add(path);
      missingPaths.add(path);
    }
  });

  useEffect(() => {
    return () => {
      clearTimeout(timeoutRef.current);
    };
  }, []);

  return handleMissingPath;
}

/**
 * Creates a new help system branch. Merges parent and new context if available.
 * This component also fetches help texts from the server. This is done by using the given path
 * prefix and extracting the first part of the path as a namespace. Skip loading, if there is a
 * parent path prefix
 */
export function HelpSystemBranchProvider({ children, pathPrefix }: { children?: ReactNode; pathPrefix?: string }) {
  const parent = useContext(HelpSystemBranchContext);
  const helpTexts = useHelpTexts({ parent, pathPrefix });
  const context = useMemo(
    () => createHelpSystemBranchContext({ parent, pathPrefix, helpTexts }),
    [helpTexts, parent, pathPrefix],
  );

  return <HelpSystemBranchContext.Provider value={context}>{children}</HelpSystemBranchContext.Provider>;
}

/**
 * Hook for loading help texts by namespace. Namespace is extracted from the current path prefixes.
 * Skips loading if a parent has path prefix.
 */
function useHelpTexts({ parent, pathPrefix }: { parent?: HelpSystemBranchContextProps; pathPrefix?: string }) {
  const namespace = parent?.pathPrefix == null ? pathPrefix?.split('.').at(0) : undefined;
  return useFetcher(
    fetchHelpTexts,
    { namespace: namespace! },
    {
      active: namespace != null,
      immutable: true,
    },
  );
}

/**
 * Hotkey test function for enabling inspect mode.
 */
const isInspectHotkey = isHotkey('mod+shift+h');

/**
 * Hook for integrating inspect hotkey if enabled.
 */
function useHelpSystemInspect({ enabled }: { enabled?: boolean }) {
  const [inspect, setInspect] = useState<boolean>();

  useEffect(() => {
    if (!enabled) {
      return;
    }

    const abortController = new AbortController();

    window.addEventListener(
      'keydown',
      (event) => {
        if (isInspectHotkey(event)) {
          setInspect((inspect) => !inspect);
        }
      },
      { signal: abortController.signal },
    );

    return () => {
      abortController.abort();
    };
  }, [enabled]);

  return inspect;
}

/**
 * Add prefix from the current help system context if available. Return path unchanged otherwise.
 */
export function prefixPath(context: HelpSystemBranchContextProps, path: string) {
  if (context.pathPrefix != null) {
    return `${context.pathPrefix}.${path}`;
  }

  return path;
}
