import { HStack, Stack } from '@chakra-ui/react';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { EventEvaluationDto } from '../../../api';
import NumberInputControl from '../../../ui/form/number-input-control';

export default function EvaluationAccessOccupancyControl() {
  const { t } = useTranslation('event_evaluation');
  useFormContext<EventEvaluationDto>();

  return (
    <>
      <Stack spacing={6}>
        <NumberInputControl label={t('tickets_issued.label')} name="ticketsIssued" min={0} max={9999} />
        <HStack spacing={6} alignItems="start">
          <NumberInputControl label={t('access.with_ticket')} name="accessWithTicket" min={0} max={9999} />
          <NumberInputControl label={t('access.without_ticket')} name="accessWithoutTicket" min={0} max={9999} />
        </HStack>
        <NumberInputControl label={t('occupancy.label')} name="occupancy" isRequired min={0} max={9999} />
      </Stack>
    </>
  );
}
