import {
  IconButton,
  IconButtonProps,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Portal,
} from '@chakra-ui/react';
import { faQuestionCircle } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import RichTextRenderer from '../../../ui/rich-text/rich-text-renderer';
import HelpText from './help-text';

export interface HelperPopoverProps {
  path: string;
  ariaLabel?: string;
  variant?: string;
  size?: IconButtonProps['size'];
}

export default function HelperPopover({ path, ariaLabel, variant = 'ghost', size }: HelperPopoverProps) {
  const { t } = useTranslation('common');

  return (
    <HelpText path={`${path}.popover`}>
      {({ helpText }) => (
        <Popover variant="helper">
          <PopoverTrigger>
            <IconButton
              variant={variant}
              size={size}
              icon={<FontAwesomeIcon icon={faQuestionCircle} />}
              aria-label={ariaLabel ?? t('show_help')}
              height="6"
              minWidth="6"
              onClick={(event) => event.stopPropagation()}
            />
          </PopoverTrigger>
          <Portal>
            <PopoverContent rootProps={{ zIndex: 'modal' }}>
              <PopoverArrow />
              <PopoverBody fontWeight="medium">
                <RichTextRenderer text={helpText.text} />
              </PopoverBody>
            </PopoverContent>
          </Portal>
        </Popover>
      )}
    </HelpText>
  );
}
