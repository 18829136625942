import {
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spacer,
} from '@chakra-ui/react';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { v4 as uuid } from 'uuid';
import { CompanyReferenceDto, FormatCompanyRelationDto, FormatCompanyStatusDto } from '../../../../api';
import formatCompanyApi from '../../../../data-access/format-company-api';
import Form from '../../../../ui/form/form';
import SubmitButton from '../../../../ui/form/submit-button';
import useToast from '../../../../ui/use-toast/use-toast';
import now from '../../../../util/now';
import { HelpSystemBranchProvider } from '../../../help-system/common/help-system-context';
import { formatCompanyFetcher } from '../format-company-queries';
import ConnectionControl from './connection-control';

interface CompanyFormatEditorDialogProps {
  isOpen: boolean;
  onClose: () => void;
  companyReference: CompanyReferenceDto;
}

export default function CompanyFormatEditorDialog({
  isOpen,
  onClose,
  companyReference,
}: CompanyFormatEditorDialogProps) {
  const initialFocusRef = React.useRef<HTMLInputElement>(null);

  return (
    <Modal isOpen={isOpen} onClose={onClose} closeOnOverlayClick={false} initialFocusRef={initialFocusRef}>
      <ModalOverlay />
      <ModalContent>
        <CompanyFormatEditor onClose={onClose} companyReference={companyReference} initialFocusRef={initialFocusRef} />
      </ModalContent>
    </Modal>
  );
}

interface CompanyFormatEditorProps {
  onClose: () => void;
  companyReference: CompanyReferenceDto;
  initialFocusRef: React.RefObject<HTMLInputElement>;
}

function CompanyFormatEditor({ onClose, companyReference, initialFocusRef }: CompanyFormatEditorProps) {
  const { t } = useTranslation(['format', 'company', 'common']);

  const form = useForm<FormatCompanyRelationDto>({
    mode: 'all',
    defaultValues: {
      id: uuid(),
      status: FormatCompanyStatusDto.ACTIVE,
      validityPeriod: { start: new Date(now()) },
    },
  });

  const showSuccessToast = useToast({
    status: 'success',
  });

  const handleSubmit = async (companyFormat: FormatCompanyRelationDto) => {
    await formatCompanyApi.createFormatCompanyByFormat({
      formatCompanyRelationDto: {
        ...companyFormat,
        company: companyReference,
      },
    });

    await formatCompanyFetcher.mutate();

    showSuccessToast({
      title: <Trans t={t} i18nKey="format:companyConnections.toast.successTitle" />,
      description: <Trans t={t} i18nKey="format:companyConnections.toast.successDescription" />,
    });

    onClose();
  };

  const formIsDirty = Object.keys(form.formState.dirtyFields).length > 0;
  const formIsValid = form.formState.isValid;

  return (
    <HelpSystemBranchProvider pathPrefix="companyFormat.editor">
      <FormProvider {...form}>
        <Form<FormatCompanyRelationDto> onValid={handleSubmit} initialFocusRef={initialFocusRef}>
          <ModalHeader>{t('format:companyConnections.add')}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <ConnectionControl companyReference={companyReference} initialFocusRef={initialFocusRef} />
          </ModalBody>
          <ModalFooter>
            <Flex width="100%">
              <Spacer />
              <Button mr={3} onClick={onClose}>
                {t('common:action.abort')}
              </Button>
              <SubmitButton variant="primary" isDisabled={!formIsDirty || !formIsValid}>
                {t('common:action.add')}
              </SubmitButton>
            </Flex>
          </ModalFooter>
        </Form>
      </FormProvider>
    </HelpSystemBranchProvider>
  );
}
