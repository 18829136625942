import {
  Button,
  ButtonGroup,
  FormControl,
  FormLabel,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
} from '@chakra-ui/react';
import { omit } from 'lodash-es';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import useSWR from 'swr';
import {
  EditionDto,
  FormatDto,
  type FormatOccupationRelationDto,
  OccupationDto,
  OccupationFormatConfirmationStatusDto,
  PersonDto,
} from '../../../../api';
import formatOccupationApi from '../../../../data-access/format-occupation-api';
import personApi from '../../../../data-access/person-api';
import Form from '../../../../ui/form/form';
import ValueAsyncSelectFormControl from '../../../../ui/form/select-control/value-async-select-form-control';
import ValueSelectFormControl from '../../../../ui/form/select-control/value-select-form-control';
import SubmitButton from '../../../../ui/form/submit-button';
import ValueSelect from '../../../../ui/select/value-select';
import useToast from '../../../../ui/use-toast/use-toast';
import useActiveEdition from '../../../edition/use-active-edition/use-active-edition';
import { HelpSystemBranchProvider } from '../../../help-system/common/help-system-context';
import { formatOccupationFetcher } from '../format-occupation-queries';

interface FormatOccupationEditorDialogProps {
  isOpen: boolean;
  onClose: () => void;
  format: FormatDto;
}

export default function FormatOccupationEditorDialog({ isOpen, onClose, format }: FormatOccupationEditorDialogProps) {
  const initialFocusRef = React.useRef<HTMLInputElement>(null);

  return (
    <Modal isOpen={isOpen} onClose={onClose} closeOnOverlayClick={false} initialFocusRef={initialFocusRef}>
      <ModalOverlay />
      <ModalContent>
        <FormatOccupationEditor onClose={onClose} format={format} initialFocusRef={initialFocusRef} />
      </ModalContent>
    </Modal>
  );
}

interface FormatOccupationEditorProps {
  onClose: () => void;
  format: FormatDto;
  initialFocusRef: React.RefObject<HTMLInputElement>;
}

function FormatOccupationEditor({ onClose, format, initialFocusRef }: FormatOccupationEditorProps) {
  const { t } = useTranslation(['person', 'edition', 'common']);
  const activeEdition = useActiveEdition();

  const form = useForm<FormatOccupationRelationDto>({
    mode: 'all',
    defaultValues: {
      edition: { id: activeEdition.id!, name: activeEdition.name },
      format: format,
      confirmationStatus: OccupationFormatConfirmationStatusDto.CONFIRMED,
    },
  });

  const showSuccessToast = useToast({
    status: 'success',
  });

  const handleSubmit = async (data: FormatOccupationRelationDto) => {
    await formatOccupationApi.createRelationsByFormat({
      formatOccupationRelationDto: omit(data, 'person'),
    });

    formatOccupationFetcher.mutate();

    showSuccessToast({
      title: <Trans t={t} i18nKey="person:formatConnections.toast.successTitle" />,
      description: <Trans t={t} i18nKey="person:formatConnections.toast.successDescription" />,
    });

    onClose();
  };

  const person = form.watch('person');
  const { data: occupations } = useSWR(
    person != null ? ['format-occupation', person.id] : null,
    async ([, personId]) => {
      const person = await personApi.fetchPerson({ id: personId });
      return person.occupations;
    },
    {},
  );

  const formIsDirty = Object.keys(form.formState.dirtyFields).length > 0;
  const { isValid } = form.formState;

  return (
    <HelpSystemBranchProvider pathPrefix="formatOccupation.editor">
      <FormProvider {...form}>
        <Form<FormatOccupationRelationDto> onValid={handleSubmit} initialFocusRef={initialFocusRef}>
          <ModalHeader>{t('person:formatConnections.add')}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Stack spacing={4}>
              <ValueAsyncSelectFormControl<PersonDto, FormatOccupationRelationDto, 'person'>
                name="person"
                label={t('person:person')}
                isRequired
                ref={initialFocusRef}
                placeholder={t('common:select.placeholder')}
                loadOptions={async (value) => {
                  const persons = await personApi.searchPersons({
                    q: value,
                    filter: ['status,eq,ACTIVE'],
                  });
                  return persons.content;
                }}
                renderLabel={(person) => `${person.firstName} ${person.surname} ${person.personKey}`}
                optionIdentifier={(option) => option.id!}
                onChange={async (nextPerson) => {
                  if (nextPerson != null && nextPerson.id !== person?.id) {
                    form.setValue('occupation', null as any);
                  }
                }}
              />
              <ValueSelectFormControl<OccupationDto, FormatOccupationRelationDto, 'occupation'>
                name="occupation"
                label={t('person:occupations.label')}
                isRequired
                placeholder={t('common:select.placeholder')}
                renderLabel={(occupation) => occupation.jobTitle}
                options={occupations ?? []}
                isDisabled={occupations == null}
                rules={{
                  validate: async (occupation) => {
                    const isUnique = await formatOccupationApi.isFormatOccupationUnique({
                      formatId: format.id,
                      occupationId: occupation.id,
                      editionId: activeEdition.id,
                    });

                    if (isUnique.value) {
                      return true;
                    }

                    return t('person:formatConnections.notUnique');
                  },
                }}
              />
              <FormControl isRequired>
                <FormLabel>{t('edition:edition')}</FormLabel>
                <ValueSelect<EditionDto>
                  isRequired
                  options={[activeEdition]}
                  renderLabel={(edition) => edition.name}
                  onChange={() => {}}
                  value={activeEdition}
                  defaultValue={activeEdition}
                  isDisabled
                />
              </FormControl>
            </Stack>
          </ModalBody>
          <ModalFooter>
            <ButtonGroup spacing={4}>
              <Button onClick={onClose}>{t('common:action.abort')}</Button>
              <SubmitButton variant="primary" isDisabled={!formIsDirty || !isValid}>
                {t('common:action.add')}
              </SubmitButton>
            </ButtonGroup>
          </ModalFooter>
        </Form>
      </FormProvider>
    </HelpSystemBranchProvider>
  );
}
