import {
  Alert,
  AlertDescription,
  AlertIcon,
  Box,
  Button,
  Checkbox,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  RadioGroup,
  Stack,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  CompanyReferenceDto,
  FormatCompanyListItemDto,
  FormatCompanyRelationDto,
  FormatReferenceDto,
} from '../../../../api';
import formatCompanyApi from '../../../../data-access/format-company-api';
import Connection from '../../../../ui/connection/connection';
import DateInputFormControl from '../../../../ui/form/date-input-control/date-input-form-control';
import Form from '../../../../ui/form/form';
import { Radio } from '../../../../ui/form/radio-control/radio-control';
import { companyIcon, formatIcon } from '../../../../ui/icons/business-objects';
import useToast from '../../../../ui/use-toast/use-toast';
import { HelpSystemBranchProvider } from '../../../help-system/common/help-system-context';

export interface FormatTerminateButtonProps extends React.ComponentPropsWithoutRef<'button'> {
  onSuccess?(): void;

  connectedEntity:
    | { type: 'FORMAT'; format: FormatReferenceDto }
    | {
        type: 'COMPANY';
        company: CompanyReferenceDto;
      };
  relation: FormatCompanyListItemDto;
}

enum CancelAction {
  END = 'END',
  DELETE = 'DELETE',
}

const FormatCompanyTerminateButton = React.forwardRef<HTMLButtonElement, FormatTerminateButtonProps>(
  ({ children, connectedEntity, relation, onSuccess, ...props }, ref) => {
    const { isOpen, onClose, onOpen } = useDisclosure();
    const [action, setAction] = React.useState(CancelAction.END);

    return (
      <>
        <button ref={ref} {...props} onClick={onOpen}>
          {children}
        </button>

        <Modal isOpen={isOpen} onClose={onClose} size="md" closeOnOverlayClick={false}>
          <ModalOverlay />
          <FormatTerminateDialog
            onClose={onClose}
            onSuccess={onSuccess}
            connectedEntity={connectedEntity}
            relation={relation}
            action={action}
            onActionChange={setAction}
          />
        </Modal>
      </>
    );
  },
);

export default FormatCompanyTerminateButton;

interface FormatTerminateDialogProps {
  connectedEntity:
    | { type: 'FORMAT'; format: FormatReferenceDto }
    | {
        type: 'COMPANY';
        company: CompanyReferenceDto;
      };
  relation: FormatCompanyListItemDto;
  onClose: () => void;

  onSuccess?(): void;

  action: CancelAction;

  onActionChange(action: CancelAction): void;
}

function FormatTerminateDialog({
  relation,
  connectedEntity,
  onClose,
  onSuccess,
  action,
  onActionChange,
}: FormatTerminateDialogProps) {
  const { t } = useTranslation(['common', 'format']);
  const initialFocusRef = React.useRef<HTMLInputElement>(null);
  const [deleteConfirmation, setDeleteConfirmation] = React.useState(false);

  const showSuccessToast = useToast({
    id: 'format-company-terminate-success-toast',
    status: 'success',
  });

  const form = useForm<FormatCompanyRelationDto>({
    mode: 'all',
    defaultValues: {
      ...relation,
      format: connectedEntity.type === 'FORMAT' ? connectedEntity.format : relation.format,
      company: connectedEntity.type === 'COMPANY' ? connectedEntity.company : relation.company,
    },
  });

  form.register('id');
  form.register('format');
  form.register('company');
  form.register('connectionType');
  form.register('status');

  const onCloseWithReset = () => {
    onActionChange(CancelAction.END);
    setDeleteConfirmation(false);
    onClose();
  };

  const onTerminate = async (relation: FormatCompanyRelationDto) => {
    if (action === CancelAction.END) {
      await formatCompanyApi.terminateRelation({
        formatTerminationDto: { id: relation.id!, validityPeriod: relation.validityPeriod },
      });
    } else if (action === CancelAction.DELETE) {
      await formatCompanyApi.deleteRelation({ id: relation.id! });
    }

    showSuccessToast({
      title: action === CancelAction.END ? t('format:connections.terminateToast') : t('format:connections.deleteToast'),
    });

    onClose();
    onSuccess?.();
  };

  const formatField = {
    icon: <Box as={FontAwesomeIcon} icon={formatIcon} fixedWidth />,
    element: (
      <Text as="span" fontWeight="medium">
        {relation.format.name}
      </Text>
    ),
  };
  const companyField = {
    icon: <Box as={FontAwesomeIcon} icon={companyIcon} fixedWidth />,
    element: (
      <Text as="span" fontWeight="medium">
        {relation.company.name}
      </Text>
    ),
  };
  return (
    <ModalContent>
      <HelpSystemBranchProvider pathPrefix="formatCompany.terminate">
        <FormProvider<FormatCompanyRelationDto> {...form}>
          <Form<FormatCompanyRelationDto> onValid={onTerminate} initialFocusRef={initialFocusRef}>
            <ModalHeader>{t('format:connections.terminate')}</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Stack>
                <Stack spacing={2} backgroundColor="background.highlight" pr={2} py={2} pl={3} borderRadius="base">
                  <Connection
                    start={connectedEntity.type === 'FORMAT' ? formatField : companyField}
                    end={connectedEntity.type === 'FORMAT' ? companyField : formatField}
                    size="md"
                  />
                </Stack>
                <Stack spacing={4}>
                  <Stack spacing={4} mt={4} borderColor="border.01" borderWidth={1} borderRadius={4} p={4}>
                    <RadioGroup value={action} onChange={onActionChange}>
                      <Stack spacing={4}>
                        <Radio value={CancelAction.END} label={t('format:connections.terminate')}>
                          {t('format:connections.description_end')}
                        </Radio>
                        <Radio value={CancelAction.DELETE} label={t('format:connections.delete_connection')}>
                          {t('format:connections.description_delete')}
                        </Radio>
                      </Stack>
                    </RadioGroup>
                  </Stack>

                  {action === CancelAction.END ? (
                    <HStack alignItems="start" spacing={4}>
                      <DateInputFormControl<FormatCompanyRelationDto>
                        name="validityPeriod.start"
                        isDisabled
                        label={t('format:formatCompany.start')}
                      />
                      <DateInputFormControl<FormatCompanyRelationDto>
                        name="validityPeriod.end"
                        label={t('format:formatCompany.end')}
                        isRequired
                        min={
                          relation.validityPeriod && {
                            value: relation.validityPeriod.start,
                            message: t('common:validation_error.date_end_before_date_start'),
                          }
                        }
                      />
                    </HStack>
                  ) : (
                    <>
                      <Alert status="warning">
                        <AlertIcon />
                        <AlertDescription>
                          <Text>{t('format:connections.alert_delete')}</Text>
                        </AlertDescription>
                      </Alert>

                      <Checkbox
                        onChange={(event) => setDeleteConfirmation(event.target.checked)}
                        isChecked={deleteConfirmation}
                        spacing={3}
                      >
                        {t('format:connections.delete_confirmation')}
                      </Checkbox>
                    </>
                  )}
                </Stack>
              </Stack>
            </ModalBody>
            <ModalFooter>
              <Button mr={3} onClick={onCloseWithReset}>
                {t('common:action.abort')}
              </Button>
              {action === CancelAction.END ? (
                <Button variant="primary" type="submit">
                  {t('format:connections.end')}
                </Button>
              ) : (
                <Button colorScheme="red" type="submit" isDisabled={!deleteConfirmation}>
                  {t('common:action.delete')}
                </Button>
              )}
            </ModalFooter>
          </Form>
        </FormProvider>
      </HelpSystemBranchProvider>
    </ModalContent>
  );
}
